
import ContextMenu, { ContextMenuOption } from 'app/components/contextMenus/ContextMenu.vue';
import { useI18n } from 'app/functions/use-i18n';
import { Series } from 'app/models/series';
import { RouteName } from 'app/router/constants';
import { defineComponent } from 'vue';

/**
 * @desc - The series card context menu component
 */
export default defineComponent({
  name: 'SeriesCardContextMenu',
  components: {
    ContextMenu
  },
  props: {
    /**
     * @param series - The series to display context options for
     */
    series: {
      type: Series,
      required: true
    },
    /**
     * @param reference - The element to anchor this context menu to
     */
    reference: {
      type: HTMLElement,
      required: true
    },
    subscribeText: {
      type: String,
      required: true
    }
  },
  emits: [
    'close',
    'show:tableofcontents',
    'toggle:subscription'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const menuOptions = [] as ContextMenuOption[];

    const closeContextMenu = () => {
      ctx.emit('close');
    };

    const viewToC = () => {
      ctx.emit('show:tableofcontents');
      closeContextMenu();
    };

    const toggleSubscription = () => {
      ctx.emit('toggle:subscription');
      ctx.emit('close');
    };

    // Menu Options:
    menuOptions.push({
      label: t('title.contextMenu.viewToC'),
      handler: viewToC,
      hasPopup: 'dialog'
    });
    menuOptions.push({
      label: t('title.contextMenu.viewDetailsPage'),
      location: { name: RouteName.SetDetails, params: { seriesId: props.series.id.toString() } }
    });
    menuOptions.push({
      label: props.subscribeText,
      handler: toggleSubscription
    });


    return {
      closeContextMenu,
      menuOptions,
      viewToC
    };
  }
});
