import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#prompts" }, [
    _createElementVNode("dialog", {
      ref: "dialog",
      style: _normalizeStyle(`--animation-duration: ${_ctx.animationDurationMs}ms`),
      class: _normalizeClass({
        [_ctx.$style.dialog]: true,
        [_ctx.$style.closing]: _ctx.closing
      }),
      "aria-label": _ctx.heading,
      onCancel: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args))),
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args))),
      onKeydown: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args)), ["esc"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.contentWrapper),
        onClick: _cache[1] || (_cache[1] = (e) => e.stopPropagation())
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.notch)
        }, null, 2),
        _createElementVNode("form", {
          class: _normalizeClass(_ctx.$style.container),
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"]))
        }, [
          _createElementVNode("h2", {
            class: _normalizeClass(_ctx.$style.heading)
          }, _toDisplayString(_ctx.heading), 3),
          _renderSlot(_ctx.$slots, "default")
        ], 34)
      ], 2)
    ], 46, _hoisted_1)
  ]))
}