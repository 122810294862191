
import { useForRefs } from 'app/functions/use-for-refs';
import { Loan } from 'app/models/loan';
import { Title } from 'app/models/title';
import { Chapter } from 'app/models/toc';
import { computed, defineComponent, getCurrentInstance, nextTick, ref } from 'vue';
import TitleActionButton from './TitleActionButton.vue';

export default defineComponent({
  name: 'TableOfContentsChapter',
  components: {
    TitleActionButton
  },
  props: {
    title: {
      type: Title,
      required: true
    },
    loan: {
      type: Loan,
      default: undefined as Loan | undefined
    },
    chapter: {
      type: Object as () => Chapter,
      required: true
    },
    depth: {
      type: Number,
      default: 0
    },
    maxDepth: {
      type: Number,
      default: Infinity
    },
    linkable: {
      type: Boolean,
      default: true
    }
  },
  setup: (props, ctx) => {
    const { itemRefs: children, setItemRef } = useForRefs<{ expand: Function }>(getCurrentInstance());

    const expandable = computed(() => {
      return (props.chapter.chapters?.length || 0) > 0
        && props.depth < props.maxDepth;
    });

    const expanded = ref(false);

    const link = ref<HTMLElement | null>(null);


    const expand = async () => {
      if (!expandable.value) {
        return;
      }

      expanded.value = true;

      await nextTick();

      for (const child of children) {
        child.expand();
      }
    };

    const collapse = () => expanded.value = false;

    return {
      collapse,
      expand,
      expanded,
      expandable,
      link,
      setItemRef
    };
  }
});
