import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledDropdown = _resolveComponent("LabeledDropdown")!
  const _component_Cover = _resolveComponent("Cover")!
  const _component_Card = _resolveComponent("Card")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!
  const _component_PromptDialog = _resolveComponent("PromptDialog")!

  return (_openBlock(), _createBlock(_component_PromptDialog, {
    ref: "prompt",
    heading: _ctx.$t('circ.action.borrow'),
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
    onSubmit: _ctx.submitAndClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        id: `borrow-explanation-${_ctx.title.slug}`
      }, _toDisplayString(_ctx.$t('circ.explanation.borrow')), 9, _hoisted_1),
      _createVNode(_component_LabeledDropdown, {
        modelValue: _ctx.selectedLendingPeriod,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLendingPeriod) = $event)),
        autofocus: "",
        options: _ctx.mappedLendingPeriods,
        optionLabel: "label",
        optionKey: (v) => `${v.value}-${v.unit}`,
        label: _ctx.$t('circ.lendingPeriod.dropdownLabel'),
        hideLabelOnSmallScreens: false
      }, null, 8, ["modelValue", "options", "optionKey", "label"]),
      _createVNode(_component_Card, {
        item: (_ctx.cardItem as CardableItem),
        borderless: _ctx.isMobile,
        showExpander: false,
        class: _normalizeClass(_ctx.$style.card)
      }, {
        cover: _withCtx(() => [
          _createVNode(_component_Cover, { item: _ctx.title }, null, 8, ["item"])
        ]),
        _: 1
      }, 8, ["item", "borderless", "class"]),
      (_ctx.loansRemaining)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.$style.limit)
          }, _toDisplayString(_ctx.$t('circ.limit.loansRemaining', { n: _ctx.loansRemaining })), 3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        _createVNode(_component_FormSubmitButton, {
          label: _ctx.$t('circ.action.borrow'),
          enabled: true,
          size: "small"
        }, null, 8, ["label"]),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.cancel),
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.prompt?.closeModal()))
        }, _toDisplayString(_ctx.$t('general.cancel')), 3)
      ], 2)
    ], undefined, true),
    _: 1
  }, 8, ["heading", "onSubmit"]))
}