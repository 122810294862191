
import TitlePriorReleaseList from 'app/components/TitlePriorReleaseList.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'PriorReleaseSidebar',
  components: {
    TitlePriorReleaseList,
    SidebarDialog
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    }
  },
  emits: [
    'close'
  ]
});
