import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-label", "aria-expanded"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_TableOfContentsChapter = _resolveComponent("TableOfContentsChapter", true)!

  return (_openBlock(), _createElementBlock("div", {
    "aria-label": _ctx.expandable ? _ctx.chapter.title : undefined,
    class: _normalizeClass([_ctx.$style.container, _ctx.expanded ? _ctx.$style.expanded : ''])
  }, [
    (_ctx.expandable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(_ctx.$style.arrow),
          "aria-label": _ctx.$t(`title.tableOfContents.${_ctx.expanded ? 'collapseChapter' : 'expandChapter'}`),
          "aria-expanded": _ctx.expanded,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
        }, [
          _createVNode(_component_Icon, { name: "chevron-right" })
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_TitleActionButton, {
      title: _ctx.title,
      seekTo: {
        type: 'path',
        location: _ctx.chapter.path
      },
      label: _ctx.chapter.title,
      class: _normalizeClass(_ctx.$style.title)
    }, null, 8, ["title", "seekTo", "label", "class"]),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.line)
    }, null, 2),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("ol", {
          key: 1,
          class: _normalizeClass(_ctx.$style.subChapters)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapter.chapters, (sub) => {
            return (_openBlock(), _createElementBlock("li", {
              key: sub.path
            }, [
              _createVNode(_component_TableOfContentsChapter, {
                ref_for: true,
                ref: _ctx.setItemRef,
                title: _ctx.title,
                loan: _ctx.loan,
                chapter: sub,
                depth: _ctx.depth + 1,
                maxDepth: _ctx.maxDepth,
                linkable: _ctx.linkable
              }, null, 8, ["title", "loan", "chapter", "depth", "maxDepth", "linkable"])
            ]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}