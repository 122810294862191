
import AnnotationTableRow from 'app/components/AnnotationTableRow.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    AnnotationTableRow,
    FormCheckbox
  },
  props: {
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    selectAllState: {
      type: String as PropType<FormCheckboxState>,
      required: true
    }
  },
  emits: [
    'annotations:update',
    'annotations:update:all'
  ],
  setup: (props, ctx) => {
    const updateAllAnnotations = (checked: FormCheckboxState) => {
      ctx.emit('annotations:update:all', checked === 'on');
    };

    const updateAnnotation = (index: number, annotation: SelectableAnnotation) => {
      const copy = props.annotations.slice();
      copy[index] = annotation;

      ctx.emit('annotations:update', copy);
    };

    return {
      updateAllAnnotations,
      updateAnnotation
    };
  }
});
