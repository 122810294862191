import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TitleActionsContextMenu = _resolveComponent("TitleActionsContextMenu")!
  const _component_TableOfContentsSidebar = _resolveComponent("TableOfContentsSidebar")!
  const _component_CoverView = _resolveComponent("CoverView")!
  const _component_ManualCopySidebar = _resolveComponent("ManualCopySidebar")!
  const _component_ManageCopyingSidebar = _resolveComponent("ManageCopyingSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      id: "context-menu",
      ref: "contextMenuButton",
      "aria-label": _ctx.$t('title.contextMenu.header'),
      class: {
      [_ctx.$style.contextMenuButton]: true,
      ['dark']: _ctx.darkStyle
    },
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showContextMenu = true))
    }), [
      (!_ctx.hideLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('title.contextMenu.header')), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_Icon, { name: "overflow" })
    ], 16, _hoisted_1),
    (_ctx.showContextMenu)
      ? (_openBlock(), _createBlock(_component_TitleActionsContextMenu, {
          key: 0,
          reference: _ctx.contextMenuButton,
          title: _ctx.title,
          includeCopying: _ctx.includeCopying,
          allowCopying: _ctx.allowManualCopy,
          subscribeText: _ctx.subscribeTextLong,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContextMenu = false)),
          "onShow:cover": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCoverView = true)),
          "onShow:tableofcontents": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showTableOfContents = true)),
          "onShow:manualcopy": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showManualCopy = true)),
          "onShow:managecopying": _cache[5] || (_cache[5] = ($event: any) => (_ctx.showManageCopying = true)),
          "onToggle:subscription": _ctx.toggleSubscription
        }, null, 8, ["reference", "title", "includeCopying", "allowCopying", "subscribeText", "onToggle:subscription"]))
      : _createCommentVNode("", true),
    (_ctx.showTableOfContents)
      ? (_openBlock(), _createBlock(_component_TableOfContentsSidebar, {
          key: 1,
          item: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["item", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showCoverView)
      ? (_openBlock(), _createBlock(_component_CoverView, {
          key: 2,
          title: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["title", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showManualCopy)
      ? (_openBlock(), _createBlock(_component_ManualCopySidebar, {
          key: 3,
          title: _ctx.title,
          releasesWithAnnotations: _ctx.releasesWithAnnotations,
          onClose: _ctx.hideOverlay
        }, null, 8, ["title", "releasesWithAnnotations", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showManageCopying)
      ? (_openBlock(), _createBlock(_component_ManageCopyingSidebar, {
          key: 4,
          title: _ctx.title,
          copyJobsState: _ctx.copyJobsState,
          onClose: _ctx.hideOverlay
        }, null, 8, ["title", "copyJobsState", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}