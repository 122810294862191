import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Downloader } from 'app/base/updates/downloader';
import { useAppEvents } from 'app/functions/use-app-events';
import { useNetworkStatus } from 'app/functions/use-network-status';
import { Loan } from 'app/models/loan';
import { ref } from 'vue';

export const MB = 1024 * 1024;

export function useDownloadInfo(downloader: Downloader) {
  const isSetToDownload = ref(downloader.autoDownload);
  const updateIsSetToDownload = (message: { loan: Loan; autoDownload: boolean }) => {
    if (message.loan !== downloader.loan) { return; }

    isSetToDownload.value = message.autoDownload;
  };

  const isErrored = ref(downloader.invalid);
  const updateIsErrored = (message: { loan: Loan; invalid: boolean }) => {
    if (message.loan !== downloader.loan) { return; }

    isErrored.value = message.invalid;

  };

  const progress = ref(downloader.downloadPercent);
  const updateProgress = (message: { loan: Loan; downloadPercent?: number }) => {
    if (message.loan !== downloader.loan) { return; }

    progress.value = message.downloadPercent || 0;
  };

  const bytesToMegabytes = (bytes: number) => {
    const bytesPerMegabyte = MB;

    return C.smoothFloat(bytes / bytesPerMegabyte, 1);
  };
  const downloadSize = ref(bytesToMegabytes(downloader.totalBytes));
  const updateDownloadSize = (message: { loan: Loan; totalBytes: number }) => {
    if (message.loan !== downloader.loan) { return; }

    downloadSize.value = bytesToMegabytes(message.totalBytes);
  };

  const isPaused = ref(APP.updateManager.paused);
  const updateIsPaused = (message: { paused: boolean }) => {
    isPaused.value = message.paused;
  };

  const { metered: isMeteredConnection } = useNetworkStatus();

  useAppEvents({
    'downloads:autoDownload': (evt) => updateIsSetToDownload(evt.m),
    'downloads:invalid': (evt) => updateIsErrored(evt.m),
    'downloads:downloadPercent': (evt) => updateProgress(evt.m),
    'loan:download:complete': (evt) => updateProgress(evt.m),
    'loan:download:wipe': (evt) => updateProgress(evt.m),
    'downloads:totalBytes': (evt) => updateDownloadSize(evt.m),
    'downloads:paused': (evt) => updateIsPaused(evt.m)
  });

  return {
    downloadSize,
    isErrored,
    isMeteredConnection,
    isPaused,
    isSetToDownload,
    progress
  };
};
