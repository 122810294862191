import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  "aria-live": "polite"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "submit",
    disabled: !_ctx.enabled,
    class: _normalizeClass({
      [_ctx.$style.button]: true,
      [_ctx.$style[_ctx.size]]: true,
      [_ctx.$style.disabled]: !_ctx.enabled
    })
  }, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.loadingText || _ctx.$t('general.loading')), 1))
  ], 10, _hoisted_1))
}