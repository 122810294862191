
import { APP } from 'app/base/app';
import { SearchThisTitleQuery } from 'app/base/hudson';
import Loading from 'app/components/Loading.vue';
import Surface from 'app/components/Surface.vue';
import TitleDetailsContent from 'app/components/TitleDetailsContent.vue';
import Events from 'app/events/events';
import { useAppEvents } from 'app/functions/use-app-events';
import { usePatron } from 'app/functions/use-patron';
import { useReleaseFilter } from 'app/functions/use-release-filter';
import i18n from 'app/i18n/i18n';
import { TDReleasesWithAnnotationsSymbol } from 'app/keys/injection-keys';
import type { Loan } from 'app/models/loan';
import { Series } from 'app/models/series';
import { TitleMapper, TitleRecord } from 'app/models/title';
import NotFound from 'app/views/NotFound.vue';
import { computed, defineComponent, provide, ref, watch } from 'vue';

type State =
  {
    state: 'loading';
  }
  | {
    state: 'loaded';
    title: TitleRecord;
    series: Series | null;
    priorReleases?: TitleRecord[];
  }
  | {
    state: 'notfound';
    label: string;
  };

export default defineComponent({
  name: 'TitleDetails',
  components: {
    Loading,
    NotFound,
    Surface,
    TitleDetailsContent
  },
  beforeRouteLeave: (to, from, next) => {
    if (to.path.indexOf('/open/') < 0) {
      APP.activeTitle.clearAnchor();
    }

    next();
  },
  props: {
    titleSlug: {
      type: String,
      required: true
    },
    searchThisTitle: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    },
    libraryId: {
      type: String,
      required: true
    }
  },
  setup: (props, ctx) => {
    const state = ref<State>({ state: 'loading' });

    const recommended = ref<TitleRecord[]>([]);

    const loan = ref<Loan | null>(null);

    const searchParameters = computed(() => {
      if (props.searchThisTitle) {
        return props.searchThisTitle;
      }

      return null;
    });

    watch(() => props.titleSlug, async (to) => {
      state.value = { state: 'loading' };
      const title = await loadTitle(props.titleSlug);

      // It's possible the user's navigated away
      // by the time we're done loading the title.
      // Let's check that we're still current.
      if (to !== props.titleSlug) {
        return;
      }

      if (title && allowAccess(title)) {
        showToastIfTitleOpenFailed(title);

        loan.value = title.loan();

        const series = title.seriesId
          ? await loadSeries(title.seriesId)
          : null;

        const priorReleases = await title.getPriorReleases();

        state.value = {
          state: 'loaded',
          title,
          series,
          priorReleases
        };

        recommended.value = await loadRecommendedTitles(props.libraryId, props.titleSlug);
      } else {
        state.value = {
          state: 'notfound',
          label: title?.freshness?.isFresh()
            ? 'title.error.unowned'
            : 'error.route.invalid'
        };
      }
    }, { immediate: true });

    useAppEvents({
      'loan:update:all': () => {
        if (state.value.state !== 'loaded') { return; }

        loan.value = state.value.title.loan();
      }
    });

    // Annotations
    const { annotations } = usePatron();
    const annotationsForTitle = computed(() => annotations.value.filter((a) => a.titleSlug === props.titleSlug));
    const annotationCount = computed(() => annotationsForTitle.value.length || 0);

    // Annotation Copying
    const releases = computed<TitleRecord[]>(() => state.value.state === 'loaded'
      ? [state.value.title, ...(state.value.priorReleases || [])] as TitleRecord[]
      : []);

    provide(TDReleasesWithAnnotationsSymbol, computed(() => {
      const { getFilterObjects: getReleaseObjects } = useReleaseFilter(annotationsForTitle, releases);

      return getReleaseObjects(annotationsForTitle.value);
    }));

    return {
      annotationCount,
      loan,
      recommended,
      searchParameters,
      state
    };
  }
});

function allowAccess(title: TitleRecord) {
    if (title.tags().length) { return true; }
    if (APP.patron.annotations.getByTitle(title.slug).length) { return true; }
    if (title.circ && (title.circ.available || title.circ.holdable)) { return true; }

    return false;
}

async function loadTitle(titleSlug: string) {
    const titleRecord = APP.library.titles.fetch(titleSlug);

    if (!titleRecord) {
      console.warn('Title not found', titleSlug);

      return undefined;
    }

    await titleRecord.freshen();

    return titleRecord;
}

/**
 * If a user followed a title open link with a linked passage,
 * but they don't have the title checked out
 * and it's non-SU or they're opening a prior release,
 * we take them to the title details page
 * so they can click the borrow button.
 * We show a toast in this scenario so they know what
 * happened.
 */
function showToastIfTitleOpenFailed(title: TitleRecord) {
  if (APP.activeTitle.anchorID
    && APP.activeTitle.isActiveTitle(title.slug)
    && title.circ?.available
  ) {
    Events.dispatch('toast', {
      type: 'info',
      message: i18n.t('circ.action.checkOutBeforeAccessingPassage')
    });
  }
}

async function loadSeries(seriesId: number): Promise<Series | null> {
  try {
    const series = new Series(seriesId);
    await series.freshen();

    return series;
  } catch (e) {
    console.error('[TITLEDETAILS] Failed to load series', e);

    return null;
  }
}

async function loadRecommendedTitles(libraryId: string, titleSlug: string): Promise<TitleRecord[]> {
  try {
    const response = await APP.services.thunder
      .getRecommendedTitles(libraryId, titleSlug, 3);

    return response?.items?.map(TitleMapper.mapFromThunder) || [];
  } catch (e) {
    console.error('[TITLEDETAILS] Could not fetch recommended titles', e);

    return [];
  }
}
