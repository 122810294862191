
import { defineComponent, onBeforeUnmount, onMounted, ref, SetupContext } from 'vue';

export default defineComponent({
  props: {
    heading: {
      type: String,
      required: true
    },
    /* Controls whether the escape key and clicking the backdrop close the dialog.
     * In most cases, just let this default to 'true' for better usability.
     */
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close',
    'submit'
  ],
  setup: (props, ctx: SetupContext) => {
    const animationDurationMs = 300;

    const dialog = ref<HTMLDialogElement | null>(null);

    const closing = ref<boolean>(false);

    const closeModal = () => {
      closing.value = true;
      setTimeout(() => {
        closing.value = false;
        ctx.emit('close');
      }, animationDurationMs);
    };

    const closeIfAllowed = (e: Event) => {
      e.preventDefault();

      if (props.dismissible) {
        closeModal();
      }
    };

    onMounted(() => {
      dialog.value?.showModal();
    });

    onBeforeUnmount(() => {
      dialog.value?.close();
    });

    ctx.expose({
      closeModal
    });

    return {
      animationDurationMs,
      dialog,
      closing,
      closeModal,
      closeIfAllowed
    };
  }
});
