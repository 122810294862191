import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManualCopyContent = _resolveComponent("ManualCopyContent")!
  const _component_SidebarDialog = _resolveComponent("SidebarDialog")!

  return (_openBlock(), _createBlock(_component_SidebarDialog, {
    ref: "dialog",
    heading: _ctx.$t('annotationCopying.manualCopy.header'),
    resizable: false,
    dismissible: !_ctx.loading,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ManualCopyContent, {
        title: _ctx.title,
        releasesWithAnnotations: _ctx.releasesWithAnnotations,
        onLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loading = true)),
        onSubmit: _ctx.onSubmit
      }, null, 8, ["title", "releasesWithAnnotations", "onSubmit"])
    ], undefined, true),
    _: 1
  }, 8, ["heading", "dismissible"]))
}