import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyJobCard = _resolveComponent("CopyJobCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("section", null, [
      _createElementVNode("h2", {
        ref: "listHeader",
        tabindex: -1,
        class: "visually-hidden"
      }, _toDisplayString(_ctx.$t('annotationCopying.manageCopying.listHeader')), 513),
      (_ctx.state.status === 'loaded' && _ctx.state.jobs.length)
        ? (_openBlock(), _createElementBlock("ol", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.jobs, (job) => {
              return (_openBlock(), _createElementBlock("li", {
                key: job.id,
                class: _normalizeClass(_ctx.$style.listItem)
              }, [
                _createVNode(_component_CopyJobCard, {
                  job: job,
                  onUpdate: (val) => _ctx.update(job.id, val)
                }, null, 8, ["job", "onUpdate"])
              ], 2))
            }), 128))
          ]))
        : (_ctx.state.status === 'loaded')
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 1,
              header: _ctx.$t('annotationCopying.manageCopying.emptyState.header'),
              content: _ctx.$t('annotationCopying.manageCopying.emptyState.content'),
              linkLabel: _ctx.$t('annotationCopying.manageCopying.emptyState.link'),
              link: _ctx.helpLink,
              isCompact: true,
              class: _normalizeClass(_ctx.$style.empty)
            }, null, 8, ["header", "content", "linkLabel", "link", "class"]))
          : (_ctx.state.status === 'loading')
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 2,
                name: "spinner",
                class: _normalizeClass(_ctx.$style.loading),
                "aria-label": _ctx.$t('general.loading')
              }, null, 8, ["class", "aria-label"]))
            : (_openBlock(), _createElementBlock("p", {
                key: 3,
                class: _normalizeClass(_ctx.$style.error)
              }, _toDisplayString(_ctx.$t('general.error')), 3))
    ])
  ], 2))
}