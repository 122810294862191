import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ManageCopyingSidebar = _resolveComponent("ManageCopyingSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.status)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          "aria-label": _ctx.$t(`annotationCopying.titleCard.${_ctx.status}`),
          "aria-haspopup": "dialog",
          class: _normalizeClass(_ctx.$style.status),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSidebar = true))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.iconContainer)
          }, [
            (_ctx.hasError)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  name: "warning",
                  class: _normalizeClass(_ctx.$style.error)
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            (_ctx.status === 'copying')
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 1,
                  name: "spinner",
                  class: _normalizeClass(_ctx.$style.icon)
                }, null, 8, ["class"]))
              : (_openBlock(), _createBlock(_component_Icon, {
                  key: 2,
                  name: "dl-downloaded",
                  class: _normalizeClass(_ctx.$style.icon)
                }, null, 8, ["class"]))
          ], 2),
          (!_ctx.actionsIconsOnly)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(_ctx.$style.label)
              }, _toDisplayString(_ctx.$t(`annotationCopying.titleCard.${_ctx.status}`)), 3))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.showSidebar)
      ? (_openBlock(), _createBlock(_component_ManageCopyingSidebar, {
          key: 1,
          title: _ctx.title,
          copyJobsState: _ctx.copyJobsState,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSidebar = false))
        }, null, 8, ["title", "copyJobsState"]))
      : _createCommentVNode("", true)
  ], 64))
}