import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { scope: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.about)
    }, [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.$style.header)
      }, _toDisplayString(_ctx.$t('header.account.info.about.header')), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.logos)
      }, [
        _createVNode(_component_Icon, {
          name: "od-logo",
          class: _normalizeClass(_ctx.$style.odLogo)
        }, null, 8, ["class"]),
        _createVNode(_component_Icon, {
          name: "ln-logo",
          class: _normalizeClass(_ctx.$style.lnLogo)
        }, null, 8, ["class"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('login.copyright', { year: new Date().getFullYear() })), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.links)
      }, [
        _createElementVNode("a", {
          href: _ctx.privacyPolicyLink,
          target: "_blank"
        }, _toDisplayString(_ctx.$t('login.privacyPolicy')), 9, _hoisted_1),
        _createElementVNode("a", {
          href: _ctx.termsLink,
          target: "_blank"
        }, _toDisplayString(_ctx.$t('login.termsAndConditions')), 9, _hoisted_2),
        _createElementVNode("a", {
          href: _ctx.copyrightLink,
          target: "_blank"
        }, _toDisplayString(_ctx.$t('header.account.info.about.copyrightNotice')), 9, _hoisted_3),
        _createElementVNode("a", {
          href: _ctx.releaseNoteLink,
          target: "_blank"
        }, _toDisplayString(_ctx.$t('header.account.info.about.releaseNotes')), 9, _hoisted_4)
      ], 2)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info, (data, category) => {
      return (_openBlock(), _createElementBlock("section", { key: category }, [
        _createElementVNode("h2", {
          class: _normalizeClass(_ctx.$style.header)
        }, _toDisplayString(_ctx.$t(`header.account.info.${category}.header`)), 3),
        _createElementVNode("table", {
          class: _normalizeClass(_ctx.$style.table)
        }, [
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (value, label) => {
              return (_openBlock(), _createElementBlock("tr", { key: label }, [
                _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t(`header.account.info.${category}.${label}`)), 1),
                _createElementVNode("td", null, _toDisplayString(value), 1)
              ]))
            }), 128))
          ])
        ], 2)
      ]))
    }), 128))
  ], 2))
}