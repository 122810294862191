
import { AnnotationDetailsAnnotation } from 'app/components/AnnotationDetailsContent.vue';
import AnnotationContextMenuButton from 'app/components/contextMenus/AnnotationContextMenuButton.vue';
import { watchMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    AnnotationContextMenuButton
  },
  props: {
    selected: {
      type: Array as PropType<AnnotationDetailsAnnotation[]>,
      required: true
    },
    menuType: {
      type: String as PropType<'annotation-details' | 'export-queue'>,
      required: false,
      default: 'annotation-details'
    },
    focusTargetId: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'selected:clear'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const reference = ref<HTMLElement | null>(null);

    const disabled = computed(() => props.selected.length === 0);
    const showContextMenu = ref(false);
    const openMenu = () => {
      if (disabled.value) { return; }

      showContextMenu.value = true;
    };


    const selectedCount = computed(() => t('annotations.selectedCount', { COUNT: props.selected.length }));
    watchMessage('numSelected', selectedCount, false);

    return {
      disabled,
      openMenu,
      reference,
      selectedCount
    };
  }
});
