import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "visually-hidden" }
const _hoisted_4 = {
  class: "metadata-label",
  scope: "row"
}
const _hoisted_5 = { class: "metadata line-clamp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarDialog = _resolveComponent("SidebarDialog")!

  return (_openBlock(), _createBlock(_component_SidebarDialog, {
    heading: _ctx.$t('title.tableOfContents.header'),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    secondHeader: _withCtx(() => [
      _createElementVNode("header", {
        class: _normalizeClass(_ctx.$style.header)
      }, [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.$style.title),
          innerHTML: _ctx.item.title
        }, null, 10, _hoisted_1),
        ('subtitle' in _ctx.item)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass([_ctx.$style.subtitle, 'line-clamp']),
              innerHTML: _ctx.item.subtitle
            }, null, 10, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.metadataList.length && !_ctx.hideMetadata)
          ? (_openBlock(), _createElementBlock("table", {
              key: 1,
              class: _normalizeClass(_ctx.$style.metadata)
            }, [
              _createElementVNode("caption", _hoisted_3, _toDisplayString(_ctx.$t('title.metadata.description')), 1),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadataList, (metadataItem) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: metadataItem.label
                  }, [
                    _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t(metadataItem.label, metadataItem.subs)), 1),
                    _createElementVNode("td", _hoisted_5, _toDisplayString(metadataItem.value), 1)
                  ]))
                }), 128))
              ])
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.itemType === 'series' ? 'SeriesTableOfContents' : 'TableOfContents'), {
        title: _ctx.itemType === 'title' ? _ctx.item : undefined,
        series: _ctx.itemType === 'series' ? _ctx.item : undefined,
        titleMap: _ctx.volumes ? _ctx.volumes.all : {}
      }, null, 8, ["title", "series", "titleMap"]))
    ]),
    _: 1
  }, 8, ["heading"]))
}