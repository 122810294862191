import { Ref } from 'vue';

type ResizerBounds = {
  minWidth: number;
} | {
  maxWidth: number;
};

export const useResizer = (element: Ref<HTMLElement | null>, side: 'left' | 'right', bounds: ResizerBounds) => {

  const resize = (event: MouseEvent) => {
    if (!element.value || event.button !== 0) { return; }

    document.addEventListener('mousemove', handler);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', handler);
    }, { once: true });
  };

  const handler = (e: MouseEvent) => {
    e.preventDefault();

    const width = side === 'right'
      ? window.innerWidth - e.x
      : e.x;

    if ('maxWidth' in bounds) {
      element.value!.style.width = `${Math.min(bounds.maxWidth, width)}px`;
    } else {
      element.value!.style.width = `${Math.max(bounds.minWidth, width)}px`;
    }
  };

  return {
    resize
  };
};
