import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableOfContentsChapter = _resolveComponent("TableOfContentsChapter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.showTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.titleContainer)
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.title),
            innerHTML: _ctx.title.title
          }, null, 10, _hoisted_1),
          (_ctx.title.subtitle)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: _ctx.title.subtitle
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.state === 'loading')
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('title.tableOfContents.loading')), 1))
      : (_ctx.state === 'failed')
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('title.tableOfContents.error')), 1))
        : (_ctx.toc.chapters)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (_ctx.expandable)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.actions)
                  }, [
                    _createElementVNode("button", {
                      class: _normalizeClass(_ctx.$style.action),
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.expand && _ctx.expand(...args)))
                    }, _toDisplayString(_ctx.$t('title.tableOfContents.expandAll')), 3),
                    _createElementVNode("span", {
                      "aria-hidden": true,
                      class: _normalizeClass(_ctx.$style.separator)
                    }, null, 2),
                    _createElementVNode("button", {
                      class: _normalizeClass(_ctx.$style.action),
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.collapse && _ctx.collapse(...args)))
                    }, _toDisplayString(_ctx.$t('title.tableOfContents.collapseAll')), 3)
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("ol", {
                class: _normalizeClass(_ctx.$style.chapterList)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toc.chapters, (chapter) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: chapter.path,
                    class: _normalizeClass(_ctx.$style.row)
                  }, [
                    _createVNode(_component_TableOfContentsChapter, {
                      ref_for: true,
                      ref: _ctx.setItemRef,
                      title: _ctx.title,
                      loan: _ctx.loan,
                      chapter: chapter,
                      maxDepth: 3,
                      linkable: _ctx.linkable
                    }, null, 8, ["title", "loan", "chapter", "linkable"])
                  ], 2))
                }), 128))
              ], 2)
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", { innerHTML: _ctx.fallback }, null, 8, _hoisted_6)
            ]))
  ], 2))
}