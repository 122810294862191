
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Constants } from 'app/base/constants';
import DebugConsole from 'app/components/DebugConsole.vue';
import FocusTrap from 'app/components/FocusTrap.vue';
import GdprModal from 'app/components/GdprModal.vue';
import HeaderBar from 'app/components/HeaderBar.vue';
import Loading from 'app/components/Loading.vue';
import NavigationSidebar from 'app/components/NavigationSidebar.vue';
import NewRelease from 'app/components/NewRelease.vue';
import NtcPrompt from 'app/components/NtcPrompt.vue';
import Overlay from 'app/components/Overlay.vue';
import Toast from 'app/components/Toast.vue';
import { provideNtcContext } from 'app/contexts/ntc-context';
import { providePrivacySettingsContext } from 'app/contexts/privacy-settings-context';
import { useAlertGeneration } from 'app/functions/use-alert-generation';
import { useAlertsWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { AlertsWithMetadataSymbol, SubscriptionsWithMetadataSymbol } from 'app/keys/injection-keys';
import { Timeline } from 'app/router/constants';
import Quirks from 'lib/gala/src/quirks';
import { computed, defineComponent, onMounted, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    DebugConsole,
    FocusTrap,
    Loading,
    HeaderBar,
    NavigationSidebar,
    NewRelease,
    NtcPrompt,
    Overlay,
    GdprModal,
    Toast
},
  beforeRouteUpdate(to, from, next) {
    if (Quirks.ask('ios-safari')) {
      this.transitionName = '';
    } else {
      if (to.meta?.timeline && from && (to.meta.timeline === from.meta?.timeline)) {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        this.transitionName = toDepth === fromDepth
          ? ''
          : toDepth < fromDepth
            ? 'back'
            : 'forward';
      } else {
        this.transitionName = '';
      }
    }

    next();
  },
  setup: (_, ctx) => {
    const surfaces = ref<HTMLElement | null>(null);

    const transitionName = ref('forward');

    const route = useRoute();

    const subscriptionsWithMetadataContext = useSubscriptionsWithMetadata();
    provide(SubscriptionsWithMetadataSymbol, subscriptionsWithMetadataContext);
    const alertsWithMetadataContext = useAlertsWithMetadata();
    provide(AlertsWithMetadataSymbol, alertsWithMetadataContext);
    const { startAlertGeneration } = useAlertGeneration(subscriptionsWithMetadataContext, alertsWithMetadataContext);
    const startAlertGenerationSoon = C.debounce(() => { startAlertGeneration(); }, 5 * C.MS_SECOND);

    if (APP.patron.accountId) {
      startAlertGenerationSoon();
    } else {
      APP.events.on('patron:accountId:acquired', startAlertGenerationSoon);
    }

    onMounted(() => {
      if (!surfaces.value) { return; }

      // ShellNone uses this to attach the Bifocal view.
      APP.arena.surface = surfaces.value;
      APP.events.dispatch('arena:ready');


      APP.events.on('msg:ui:scroll-to-top', () => APP.arena.scroller?.scrollToTop());
    });

    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.HideNav);
    const narrow = computed(() => windowWidth.value <= Breakpoint.Narrow);
    const showNav = ref(false);

    const showChrome = ref(false);
    watch(route, (to) => {
      APP.arena.scroller?.announce();
      showChrome.value = to.meta?.timeline !== Timeline.Onboarding;
      showNav.value = false;
    }, { immediate: true });

    const showDebug = ref(false);
    APP.events.on('show:debug', () => showDebug.value = true);

    const showSurvey = ref(false);
    const surveyAskAgainDate = APP.bank.get('survey:ask-again');
    if (!surveyAskAgainDate || new Date() > new Date(surveyAskAgainDate)) {
      onMounted(() => {
        setTimeout(() => showSurvey.value = true, Constants.SURVEY_TRIGGER_MILLISECONDS);
      });
    }

    const {
      shouldShowSettings,
      defaultSetting,
      showSettings,
      hideSettings,
      saveSettings
    } = providePrivacySettingsContext();

    // Automatically show the GDPR modal if necessary
    APP.tracking.bankStart.then((allowed) => {
      if (allowed === undefined) {
        showSettings();
      }
    });

    const { shouldShowPrompt: shouldShowNtc, providerId, submit: submitNtc, hide: hideNtc } = provideNtcContext();

    return {
      mobile,
      narrow,
      transitionName,
      showChrome,
      showDebug,
      showNav,
      showSurvey,
      surfaces,

      shouldShowSettings,
      defaultSetting,
      hideSettings,
      saveSettings,

      shouldShowNtc,
      providerId,
      submitNtc,
      hideNtc
    };
  }
});
