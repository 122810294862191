import { HighlightColor, HighlightColorGroup } from 'app/base/constants';
import { useI18n } from 'app/functions/use-i18n';
import { getReleaseDisplayNameAnnotation } from 'app/functions/use-release-display-name';
import { Annotation } from 'app/models/annotation';
import { TitleRecord } from 'app/models/title';
import { releaseDateToDate } from 'lib/common/date';
import { Ref, computed } from 'vue';

// Only the relevant data pieces of the model object
type AnnotationData = Pick<Annotation,
      'uuid'
    | 'colorID'
    | 'quote'
    | 'note'
    | 'citation'
    | 'titleSlug'
    | 'release'
    | 'releaseDate'
    | 'releaseSlug'
    | 'syncstamp'
    | 'timestamp'> &
    {
      releaseTitleRecord?: TitleRecord;
      parentTitleRecord?: TitleRecord;
    };

export type SelectableAnnotation = AnnotationData & { selected: boolean };

export type DisplayAnnotation = {
  id: string;
  colorGroup: HighlightColorGroup;
  highlight?: string;
  note?: string;
  citation: string;
  titleSlug: string;
  release: string;
  releaseDate: string;
  releaseDisplayName: string;
  created: number;
  lastUpdated: number;
  selected: boolean;
  releaseTitleRecord?: TitleRecord;
  parentTitleRecord?: TitleRecord;
};

export function useDisplayAnnotation(annotation: Ref<SelectableAnnotation>) {
  const { t } = useI18n();

  const sanitize = (text: string | null | undefined) => {
    if (!text) {
      return undefined;
    }

    return text.replace(/\\n/g, '\n');
  };

  const displayAnnotation = computed<DisplayAnnotation>(() => {
    const a = annotation.value;

    return {
      id: a.uuid,
      colorGroup: HighlightColor[a.colorID] as HighlightColorGroup,
      highlight: sanitize(a.quote),
      note: sanitize(a.note),
      citation: a.citation,
      titleSlug: a.titleSlug,
      release: a.release,
      releaseDate: t('time.date', { date: releaseDateToDate(a.releaseDate) }),
      releaseDisplayName: getReleaseDisplayNameAnnotation(a),
      created: a.timestamp * 1000,
      lastUpdated: a.syncstamp,
      selected: a.selected,
      releaseTitleRecord: a.releaseTitleRecord,
      parentTitleRecord: a.parentTitleRecord
    };
  });

  return {
    displayAnnotation
  };
};
