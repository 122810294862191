import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AnnotationContextMenuButton = _resolveComponent("AnnotationContextMenuButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.selectionToolbar)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.selectedCount)
    }, _toDisplayString(_ctx.selectedCount), 3),
    _createVNode(_component_AnnotationContextMenuButton, {
      id: _ctx.focusTargetId,
      annotations: _ctx.selected,
      buttonClass: _ctx.$style.menuButton,
      disabled: _ctx.disabled,
      attrs: _ctx.menuType === 'export-queue' ? {
        type: _ctx.menuType,
        exportOptions: true,
        showCount: true
      } : {
        type: _ctx.menuType,
        showCount: true,
        showSearchOption: false
      },
      "onSelected:clear": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selected:clear')))
    }, {
      buttonContent: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('annotations.contextMenu.button')) + " ", 1),
        _createVNode(_component_Icon, {
          class: _normalizeClass(_ctx.$style.menuButtonIcon),
          name: "chevron-down"
        }, null, 8, ["class"])
      ]),
      _: 1
    }, 8, ["id", "annotations", "buttonClass", "disabled", "attrs"])
  ], 2))
}