import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BackToTopButton = _resolveComponent("BackToTopButton")!

  return (_openBlock(), _createElementBlock("dialog", {
    ref: "dialog",
    class: _normalizeClass([_ctx.$style.dialog, _ctx.$style[_ctx.side], { [_ctx.$style.animationOpen]: _ctx.animationOpen }]),
    style: _normalizeStyle([_ctx.styles, `--min-width: ${_ctx.minWidth}px`]),
    "aria-label": _ctx.heading,
    onCancel: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args))),
    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args))),
    onKeydown: _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args)), ["esc"]))
  }, [
    (_ctx.resizable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "resizer",
          class: _normalizeClass(_ctx.$style.resizer),
          onClick: _cache[0] || (_cache[0] = (e) => e.stopPropagation()),
          onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resize && _ctx.resize(...args)))
        }, null, 34))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.contentWrapper),
      onClick: _cache[4] || (_cache[4] = (e) => e.stopPropagation())
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.headerBar)
      }, [
        _createElementVNode("h2", {
          class: _normalizeClass([_ctx.$style.heading, _ctx.headingClass])
        }, [
          _createTextVNode(_toDisplayString(_ctx.heading) + " ", 1),
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                name: _ctx.icon,
                class: _normalizeClass(_ctx.$style.headingIcon)
              }, null, 8, ["name", "class"]))
            : _createCommentVNode("", true)
        ], 2),
        _renderSlot(_ctx.$slots, "extra"),
        (_ctx.dismissible)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(_ctx.$style.dismiss),
              focusOnScroll: "",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)))
            }, _toDisplayString(_ctx.$t('general.close')), 3))
          : _createCommentVNode("", true)
      ], 2),
      _renderSlot(_ctx.$slots, "secondHeader"),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.scrollable)
      }, [
        _createElementVNode("span", {
          ref: "topOfContent",
          class: _normalizeClass(_ctx.$style.emptySpan)
        }, null, 2),
        _renderSlot(_ctx.$slots, "default"),
        _withDirectives(_createVNode(_component_BackToTopButton, {
          scrollTo: _ctx.topOfContent!,
          focusOn: _ctx.focusOnScroll,
          onShow: _cache[3] || (_cache[3] = (shouldShow) => _ctx.showBackToTop = shouldShow)
        }, null, 8, ["scrollTo", "focusOn"]), [
          [_vShow, _ctx.showBackToTop]
        ])
      ], 2)
    ], 2)
  ], 46, _hoisted_1))
}