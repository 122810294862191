
import FormError from 'app/components/FormError.vue';
import Icon from 'app/components/Icon.vue';
import { useI18n } from 'app/functions/use-i18n';
import { generateUUID } from 'lib/common';
import { PropType, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FormSearch',
  components: {
    Icon,
    FormError
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    labelId: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    focusId: {
      type: String,
      default: undefined
    },
    hasPopup: {
      type: String as PropType<'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'>,
      default: undefined
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const fallbackLabelId = `form-search-label-${generateUUID()}`;
    const searchFocusId = props.focusId || `form-search-button-${generateUUID()}`;

    const input = computed({
      get: () => props.modelValue,
      set: (val) => {
        shownError.value = null;
        ctx.emit('update:modelValue', val);
      }
    });

    const { t } = useI18n();
    const shownError = ref<string | null>(null);
    const onInvalid = () => {
      shownError.value = t(
        'form.error.missing',
        { name: props.label }
      ).toString();
    };

    return {
      fallbackLabelId,
      input,
      onInvalid,
      searchFocusId,
      shownError
    };
  }
});
