
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { defineComponent, onMounted, ref } from 'vue';

type PriorReleaseInfo = {
  release?: string;
  releaseDate?: string;
  title: TitleRecord;
  numAnnotations?: number;
};

export default defineComponent({
  name: 'TitlePriorReleaseList',
  components: {
    TitleActionButton
  },
  props: {
    title: {
      type: Title,
      required: true
    }
  },
  setup: (props) => {
    const priorReleases = ref<PriorReleaseInfo[] | null>(null);

    onMounted(async () => {
      const releases = await props.title.getPriorReleases();

      priorReleases.value = releases
        .map((releaseTitle) => {
          return {
            title: releaseTitle,
            release: releaseTitle.lexisMetadata?.release,
            releaseDate: releaseTitle.lexisMetadata?.releaseDate,
            numAnnotations: releaseTitle.annotationsForRelease()?.length || 0
          };
        });
    });

    const releaseNotesDetailsLink = (title: TitleRecord) => {
      return {
        name: RouteName.NoteDetails,
        params: {
          titleSlug: title.lexisMetadata.parent
        },
        query: {
          release: title.slug
        }
      };
    };

    return {
      priorReleases,
      releaseNotesDetailsLink
    };
  }
});
