import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "aria-labelledby", "placeholder", "required", "aria-haspopup"]
const _hoisted_3 = ["aria-label", "aria-haspopup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FormError = _resolveComponent("FormError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    (!_ctx.labelId && _ctx.placeholder)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          id: _ctx.fallbackLabelId,
          class: "visually-hidden"
        }, _toDisplayString(_ctx.placeholder), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      id: _ctx.searchFocusId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
      type: "search",
      "aria-labelledby": `${_ctx.labelId || _ctx.fallbackLabelId}`,
      placeholder: _ctx.placeholder,
      class: _normalizeClass(_ctx.$style.input),
      required: _ctx.required,
      "aria-haspopup": _ctx.hasPopup || false,
      onInvalid: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onInvalid && _ctx.onInvalid(...args)), ["prevent"]))
    }, null, 42, _hoisted_2), [
      [
        _vModelText,
        _ctx.input,
        void 0,
        { trim: true }
      ]
    ]),
    _createElementVNode("button", {
      type: "submit",
      "aria-label": _ctx.$t('form.search'),
      "aria-haspopup": _ctx.hasPopup || false,
      class: _normalizeClass(_ctx.$style.button)
    }, [
      _createVNode(_component_Icon, { name: "search" })
    ], 10, _hoisted_3),
    (_ctx.shownError)
      ? (_openBlock(), _createBlock(_component_FormError, {
          key: 1,
          class: _normalizeClass(_ctx.$style.error),
          contents: _ctx.shownError
        }, null, 8, ["class", "contents"]))
      : _createCommentVNode("", true)
  ], 2))
}