
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import Icon from 'app/components/Icon.vue';
import CopyJobCard from 'app/components/cards/CopyJobCard.vue';
import { CopyUpdateType, updateCopyJob } from 'app/functions/use-annotation-copying';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ManageCopyingContent',
  components: {
    CopyJobCard,
    EmptyState,
    Icon
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    state: {
      type: Object as PropType<CopyJobsState>,
      required: true
    }
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.COPYING;
    const listHeader = ref<HTMLElement | null>(null);

    const update = (id: number, updateType: CopyUpdateType) => {
      // Shift focus to visually hidden header
      listHeader.value?.focus();

      updateCopyJob(id, updateType);
    };

    return {
      helpLink,
      listHeader,
      update
    };
  }
});
