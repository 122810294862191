
import FilterSidebar from 'app/components/FilterSidebar.vue';
import { AnnotationFilters } from 'app/functions/use-annotation-filters';
import { SubjectFilters } from 'app/functions/use-subject-filters';
import { FilterCategory, FilterObject } from 'app/models/filter-object';
import { PropType, computed, defineComponent, ref } from 'vue';

export type FilterOptionType = 'text' | 'color';

export type FilterOptionSort = 'alphaAsc' | 'alphaDesc';

export type FilterCategoryObject = {
  id: FilterCategory;
  optionType: FilterOptionType;
  sort?: FilterOptionSort;
};

export type AppliedFilters = SubjectFilters & AnnotationFilters;

export default defineComponent({
  components: {
    FilterSidebar
  },
  props: {
    options: {
      type: Array as PropType<FilterObject[]>,
      required: true
    },
    filterType: {
      type: String as PropType<'subject' | 'annotation' | 'export'>,
      required: true
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'input'
  ],
  setup: (props, { emit }) => {
    const showFilterSidebar = ref(false);

    const categoryObjects = (props.filterType === 'annotation'
      ? [ { id: 'Release', optionType: 'text', sort: 'alphaDesc' },
        { id: 'Color', optionType: 'color' } ]
      : props.filterType === 'subject'
      ? [ { id: 'Jurisdiction', optionType: 'text' },
        { id: 'PracticeArea', optionType: 'text' },
        { id: 'Classification', optionType: 'text' },
        { id: 'Subject', optionType: 'text' } ]
      : [ { id: 'Color', optionType: 'color' } ]) as FilterCategoryObject[];

    const filterCount = computed(() => props.options.filter((option) => option.selected).length);

    const applyFilters = (filters: AppliedFilters) => {
      emit('input', filters);
    };

    return {
      categoryObjects,
      filterCount,
      showFilterSidebar,
      applyFilters
    };
  }
});
