import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SeriesCardContextMenu = _resolveComponent("SeriesCardContextMenu")!
  const _component_TableOfContentsSidebar = _resolveComponent("TableOfContentsSidebar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.actions)
  }, [
    _createVNode(_component_router_link, {
      to: _ctx.link,
      class: _normalizeClass(_ctx.$style.action)
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('series.view')), 1)
      ], undefined, true),
      _: 1
    }, 8, ["to", "class"]),
    _createElementVNode("button", {
      ref: "overflow",
      class: _normalizeClass(_ctx.$style.overflow),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showContextMenu = true))
    }, [
      _createVNode(_component_Icon, { name: "overflow" })
    ], 2),
    (_ctx.showContextMenu)
      ? (_openBlock(), _createBlock(_component_SeriesCardContextMenu, {
          key: 0,
          reference: _ctx.overflow,
          series: _ctx.series,
          subscribeText: _ctx.subscribeTextLong,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContextMenu = false)),
          "onShow:tableofcontents": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showTableOfContents = true)),
          "onToggle:subscription": _ctx.toggleSubscription
        }, null, 8, ["reference", "series", "subscribeText", "onToggle:subscription"]))
      : _createCommentVNode("", true),
    (_ctx.showTableOfContents)
      ? (_openBlock(), _createBlock(_component_TableOfContentsSidebar, {
          key: 1,
          item: _ctx.series,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showTableOfContents = false))
        }, null, 8, ["item"]))
      : _createCommentVNode("", true)
  ], 2))
}