
import { APP } from 'app/base/app';
import TitleCardContextMenu from 'app/components/contextMenus/TitleCardContextMenu.vue';
import CoverView from 'app/components/CoverView.vue';
import DownloadIndicator from 'app/components/DownloadIndicator.vue';
import LoanExpirationIndicator from 'app/components/LoanExpirationIndicator.vue';
import PriorReleaseSidebar from 'app/components/PriorReleaseSidebar.vue';
import TableOfContentsSidebar from 'app/components/TableOfContentsSidebar.vue';
import TagIndicator from 'app/components/TagIndicator.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';


export default defineComponent({
  name: 'TitleCardOptions',
  components: {
    DownloadIndicator,
    LoanExpirationIndicator,
    TableOfContentsSidebar,
    TitleActionButton,
    TitleCardContextMenu,
    PriorReleaseSidebar,
    CoverView,
    TagIndicator
},
  props: {
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    /**
     * Determines whether to only show icons for the title actions (ie/ Expiration and Download indicators)
     * Defaults to false
     */
    actionsIconsOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const loan = computed(() => props.title?.loan());
    const isDownloadableLoan = computed(() => (loan.value && APP.shell.has('rosters') && props.title.hasODRFormat));
    const isExpiring = computed(() => (loan.value && !props.title?.isSimultaneousUse));
    const iconOnly = ref<boolean>(props.actionsIconsOnly);

    const showContextMenu = ref(false);
    const overflow = ref<HTMLElement | null>(null);
    const actions = ref<HTMLElement | null>(null);

    const updateIcons = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      const cutoff = 600;

      iconOnly.value = entry.contentRect.width < cutoff;
    };

    const resizeObserver = new ResizeObserver(updateIcons);

    onMounted(() => {
      if (actions.value) {
        resizeObserver.observe(actions.value);
      }
    });


    const showCoverView = ref(false);
    const showPriorReleases = ref(false);
    const showTableOfContents = ref(false);

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'title card');

    const hideOverlay = async () => {
      showCoverView.value = false;
      showPriorReleases.value = false;
      showTableOfContents.value = false;
      await nextTick();
      overflow.value?.focus();
    };

    return {
      actions,
      hideOverlay,
      iconOnly,
      isDownloadableLoan,
      isExpiring,
      loan,
      overflow,
      showContextMenu,
      showCoverView,
      showPriorReleases,
      showTableOfContents,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
