import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "badge dark",
  "aria-hidden": true
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_ctx.reference)
    ? (_openBlock(), _createBlock(_component_ContextMenu, {
        key: 0,
        ref: "menu",
        class: _normalizeClass(_ctx.$style.menu),
        reference: _ctx.reference,
        options: _ctx.contextMenuOptions,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        read: _withCtx(() => [
          _createVNode(_component_TitleActionButton, {
            class: _normalizeClass(`${_ctx.$style.titleActionButton} dark`),
            title: _ctx.title,
            "onAction:start": _ctx.onTitleActionStart,
            "onAction:finish": _ctx.onTitleActionFinish
          }, null, 8, ["class", "title", "onAction:start", "onAction:finish"])
        ]),
        annotations: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: _ctx.annotationOption.location
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(_ctx.annotationOption.label) + " ", 1),
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.annotationOption.count), 1)
              ])
            ], undefined, true),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }, 8, ["class", "reference", "options"]))
    : _createCommentVNode("", true)
}