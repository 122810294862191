
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import ManualCopyContent from 'app/components/ManualCopyContent.vue';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'ManualCopySidebar',
  components: {
    ManualCopyContent,
    SidebarDialog
},
  props: {
     title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    releasesWithAnnotations: {
      type: Array as PropType<FilterObject[]>,
      default: () => []
    }
  },
  emits: [
    'close'
  ],
  setup: (_) => {
    const dialog = ref<InstanceType<typeof SidebarDialog> | null>(null);
    const loading = ref(false);

    const onSubmit = () => {
      dialog.value?.closeDialog();
    };

    return {
      dialog,
      loading,
      onSubmit
    };
  }
});
