
import ManageCopyingSidebar from 'app/components/ManageCopyingSidebar.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { TitleRecord } from 'app/models/title';
import { PropType, computed, defineComponent, ref } from 'vue';

type Status = 'copying' | 'copied' | undefined;

export default defineComponent({
  name: 'TitleCardCopyStatus',
  components: {
    ManageCopyingSidebar
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    copyJobsState: {
      type: Object as PropType<CopyJobsState>,
      required: true
    },
    actionsIconsOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const showSidebar = ref(false);

    const status = computed<Status>(() => {
      if (props.copyJobsState.status === 'loaded') {
        const jobs = props.copyJobsState.jobs;
        if (jobs.every((job) => job.reviewed)) {
          return undefined;
        }

        if (jobs.some((job) => job.status === 'IN_PROGRESS' || job.status === 'QUEUED')) {
          return 'copying';
        }

        return 'copied';
      }

      return undefined;
    });

    const hasError = computed(() => {
      return props.copyJobsState.status === 'loaded'
        ? props.copyJobsState.jobs.some((job) => job.status === 'ERROR')
        : false;
    });

    return {
      hasError,
      showSidebar,
      status
    };
  }
});
