
import { SearchThisTitleQuery } from 'app/base/hudson';
import SearchThisTitleForm from 'app/components/SearchThisTitleForm.vue';
import SearchThisTitleSidebar from 'app/components/SearchThisTitleSidebar.vue';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { generateUUID } from 'lib/common';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'SearchThisTitle',
  components: {
    SearchThisTitleForm,
    SearchThisTitleSidebar
  },
  props: {
    item: {
      type: Object as () => TitleRecord | Series,
      required: true
    },
    params: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    }
  },
  setup: (props) => {
    const uuid = generateUUID();
    const formId = `stt-form-${uuid}`;
    const focusOnClose = `stt-focus-${uuid}`;
    const paramsToSearch = ref(props.params);
    const showSidebar = ref(false);

    const search = (params: SearchThisTitleQuery) => {
      paramsToSearch.value = params;
      showSidebar.value = true;
    };

    const route = useRoute();
    const router = useRouter();

    const close = async () => {
      showSidebar.value = false;

      // clear out query from route
      router.replace({
        name: route.name || undefined,
        params: route.params
      });
    };

    onMounted(() => {
      if (props.params) {
        showSidebar.value = true;
      }
    });

    watch(() => showSidebar.value, () => {
      // on sidebar close
      if(!showSidebar.value) {
        setTimeout(() => {
          // scroll the form back into view and focus on input
          document.getElementById(formId)?.scrollIntoView({ behavior: 'smooth' });
          document.getElementById(focusOnClose)?.focus({ preventScroll: true });
        }, 250);
      }
    });

    return {
      focusOnClose,
      formId,
      paramsToSearch,
      showSidebar,
      close,
      search
    };
  }
});
