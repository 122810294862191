
import { APP } from 'app/base/app';
import TableOfContentsChapter from 'app/components/TableOfContentsChapter.vue';
import { useForRefs } from 'app/functions/use-for-refs';
import { useI18n } from 'app/functions/use-i18n';
import { Title } from 'app/models/title';
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'TableOfContents',
  components: {
    TableOfContentsChapter
  },
  props: {
    title: {
      type: Title,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { t } = useI18n();

    const state = ref<'loading' | 'loaded' | 'failed'>('loading');

    const toc = computed(() => props.title.bookToC);

    const fallback = ref('');

    const loan = APP.patron.loans.find({ titleSlug: props.title.slug });

    const { itemRefs: children, setItemRef } = useForRefs<{ expand: Function; collapse: Function }>(getCurrentInstance());

    const expand = () => {
      for (const child of children) {
        child.expand();
      }
    };

    const collapse = () => {
      for (const child of children) {
        child.collapse();
      }
    };

    const linkable = ref(props.title.mediaType === 'book');

    const expandable = computed(() => {
      return toc.value.chapters?.some((c) => !!c.chapters);
    });

    onMounted(async () => {
      if (props.title.hasODRFormat) {
        try {
          // We freshen the props' title so that it gets updated in place
          await props.title.bookToC.freshen();
        } catch (e) {
          console.error('[TOC] Could not fetch detailed table of contents', e);
        }
      }

      // If we have ToC data, it's always better than the Metadata version
      // even if freshening failed.
      if (toc.value.chapters?.length) {
        state.value = 'loaded';
      } else {
        try {
          fallback.value = await props.title.tableOfContents()
            || t('title.tableOfContents.empty');
          state.value = 'loaded';
        } catch (e) {
          state.value = 'failed';
          console.error('[TOC] Could not fetch fallback table of contents', e);
        }
      }
    });

    return {
      collapse,
      expand,
      expandable,
      fallback,
      linkable,
      loan,
      setItemRef,
      state,
      toc
    };
  }
});
