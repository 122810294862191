
import { defineComponent, ref } from 'vue';
import FormSubmitButton from './FormSubmitButton.vue';
import PromptDialog from './dialogs/PromptDialog.vue';

export default defineComponent({
  name: 'NewReleasePrompt',
  components: {
    PromptDialog,
    FormSubmitButton
  },
  props: {
    titleId: {
      type: String,
      default: 'titleId'
    }
  },
  emits: [
    'open'
  ],
  setup: () => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);

    return {
      prompt
    };
  }
});
