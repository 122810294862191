import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateStrip = _resolveComponent("UpdateStrip")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LibraryLogo = _resolveComponent("LibraryLogo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AlertMenu = _resolveComponent("AlertMenu")!
  const _component_UserMenu = _resolveComponent("UserMenu")!
  const _component_MobileSearch = _resolveComponent("MobileSearch")!
  const _component_SearchBar = _resolveComponent("SearchBar")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(_ctx.$style.header),
    style: _normalizeStyle(`--c-channel:${_ctx.channelColor}`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["notch", _ctx.isUpdating && _ctx.$style.updateStripNotch])
    }, null, 2),
    _createVNode(_component_UpdateStrip, {
      onShow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isUpdating = true)),
      onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isUpdating = false))
    }),
    (_ctx.mobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.mobile)
        }, [
          _createElementVNode("button", {
            "aria-label": _ctx.$t('header.menu'),
            class: _normalizeClass(_ctx.$style.icon),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('show-nav')))
          }, [
            _createVNode(_component_Icon, { name: "menu" })
          ], 10, _hoisted_1),
          _createVNode(_component_router_link, {
            to: { name: 'Home' },
            class: _normalizeClass(_ctx.$style.logo)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LibraryLogo)
            ], undefined, true),
            _: 1
          }, 8, ["class"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.menus)
          }, [
            _createVNode(_component_AlertMenu),
            _createVNode(_component_UserMenu)
          ], 2)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.strip)
    }, [
      (_ctx.mobile)
        ? (_openBlock(), _createBlock(_component_MobileSearch, {
            key: 0,
            modelValue: _ctx.query,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.query) = $event)),
            class: _normalizeClass(_ctx.$style.searchBar)
          }, null, 8, ["modelValue", "class"]))
        : (_openBlock(), _createBlock(_component_SearchBar, {
            key: 1,
            modelValue: _ctx.query,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.query) = $event)),
            class: _normalizeClass(_ctx.$style.searchBar)
          }, null, 8, ["modelValue", "class"])),
      (!_ctx.mobile)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style.menus)
          }, [
            _createVNode(_component_AlertMenu),
            _createVNode(_component_UserMenu)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 6))
}