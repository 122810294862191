import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_ManageCopyingContent = _resolveComponent("ManageCopyingContent")!
  const _component_SidebarDialog = _resolveComponent("SidebarDialog")!

  return (_openBlock(), _createBlock(_component_SidebarDialog, {
    heading: _ctx.$t('annotationCopying.manageCopying.header'),
    resizable: false,
    side: "right",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    secondHeader: _withCtx(() => [
      _createElementVNode("section", {
        class: _normalizeClass(_ctx.$style.header)
      }, [
        _createVNode(_component_Cover, {
          item: _ctx.title,
          class: _normalizeClass(_ctx.$style.cover)
        }, null, 8, ["item", "class"]),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.title)
        }, _toDisplayString(_ctx.title.title), 3),
        (_ctx.title.subtitle)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.$style.subtitle)
            }, _toDisplayString(_ctx.title.subtitle), 3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    default: _withCtx(() => [
      (_ctx.copyJobsState)
        ? (_openBlock(), _createBlock(_component_ManageCopyingContent, {
            key: 0,
            title: _ctx.title,
            state: _ctx.copyJobsState
          }, null, 8, ["title", "state"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["heading"]))
}