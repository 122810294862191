
import { useDialog } from 'app/functions/use-dialog';
import { defineComponent, SetupContext } from 'vue';

export default defineComponent({
  props: {
    heading: {
      type: String,
      required: true
    },
    headingClass: {
      type: String,
      default: undefined
    },
    /* Controls whether the escape key and clicking the backdrop close the dialog.
     * In most cases, just let this default to 'true' for better usability.
     */
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx: SetupContext) => {
    const animationDurationMs = 300;

    const {
      animationOpen,
      closeDialog,
      dialog,
      styles
    } = useDialog(ctx, { animationDurationMs });

    const closeIfAllowed = (e: Event) => {
      e.preventDefault();

      if (props.dismissible) {
        closeDialog();
      }
    };

    return {
      animationOpen,
      closeDialog,
      closeIfAllowed,
      dialog,
      styles
    };
  }
});
