import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InProgressContent = _resolveComponent("InProgressContent")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!
  const _component_PromptDialog = _resolveComponent("PromptDialog")!

  return (_openBlock(), _createBlock(_component_PromptDialog, {
    ref: "prompt",
    heading: _ctx.$t('annotationCopying.prompt.inProgress.header'),
    dismissible: false,
    onSubmit: _ctx.openBook,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit(_ctx.onCloseEvent)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_InProgressContent, { id: _ctx.inProgressPromptId }, null, 8, ["id"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        _createVNode(_component_FormSubmitButton, {
          autofocus: "",
          label: _ctx.$t('annotationCopying.prompt.inProgress.continue'),
          enabled: true,
          size: "small"
        }, null, 8, ["label"]),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.cancel),
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToAnnotations && _ctx.goToAnnotations(...args)))
        }, _toDisplayString(_ctx.$t('annotationCopying.prompt.inProgress.cancel')), 3)
      ], 2)
    ], undefined, true),
    _: 1
  }, 8, ["heading", "onSubmit"]))
}