import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ExportQueueContextMenu = _resolveComponent("ExportQueueContextMenu")!
  const _component_AnnotationDetailsContextMenu = _resolveComponent("AnnotationDetailsContextMenu")!
  const _component_SearchThisTitleSidebar = _resolveComponent("SearchThisTitleSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      ref: "actions",
      class: {
      [_ctx.buttonClass]: true,
      [_ctx.$style.disabled]: _ctx.disabled
    },
      "aria-label": _ctx.$t('annotations.display.actions.label'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disabled ? undefined : _ctx.showContextMenu = true))
    }), [
      _renderSlot(_ctx.$slots, "buttonContent", {}, () => [
        _createVNode(_component_Icon, { name: "overflow" })
      ])
    ], 16, _hoisted_1),
    (_ctx.attrs.type === 'export-queue')
      ? (_openBlock(), _createBlock(_component_ExportQueueContextMenu, {
          key: 0,
          show: _ctx.showContextMenu,
          reference: _ctx.actions,
          annotations: _ctx.annotations,
          exportOptions: _ctx.attrs.exportOptions,
          showCount: _ctx.attrs.showCount,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContextMenu = false)),
          onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('selected:clear')))
        }, null, 8, ["show", "reference", "annotations", "exportOptions", "showCount"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.showContextMenu)
            ? (_openBlock(), _createBlock(_component_AnnotationDetailsContextMenu, {
                key: 0,
                reference: _ctx.actions,
                annotations: _ctx.annotations,
                showCount: _ctx.attrs.showCount,
                showSearchOption: _ctx.attrs.showSearchOption,
                onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showContextMenu = false)),
                onCopied: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('selected:clear'))),
                onExported: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('selected:clear'))),
                onSearch: _ctx.onSearch
              }, null, 8, ["reference", "annotations", "showCount", "showSearchOption", "onSearch"]))
            : _createCommentVNode("", true)
        ], 64)),
    (_ctx.showStT)
      ? (_openBlock(), _createBlock(_component_SearchThisTitleSidebar, {
          key: 2,
          item: _ctx.annotations[0].parentTitleRecord!,
          params: _ctx.searchParams!,
          skipRouting: true,
          onClose: _ctx.closeSearch
        }, null, 8, ["item", "params", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}