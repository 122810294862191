import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchThisTitleForm = _resolveComponent("SearchThisTitleForm")!
  const _component_SearchThisTitleSidebar = _resolveComponent("SearchThisTitleSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchThisTitleForm, {
      id: _ctx.formId,
      focusId: _ctx.focusOnClose,
      item: _ctx.item,
      query: _ctx.params ? _ctx.params.query : undefined,
      scope: _ctx.params ? _ctx.params.scope : undefined,
      type: _ctx.params ? _ctx.params.type : undefined,
      includeHasPopup: true,
      onSearch: _ctx.search
    }, null, 8, ["id", "focusId", "item", "query", "scope", "type", "onSearch"]),
    (_ctx.showSidebar && _ctx.paramsToSearch)
      ? (_openBlock(), _createBlock(_component_SearchThisTitleSidebar, {
          key: 0,
          item: _ctx.item,
          params: _ctx.paramsToSearch,
          skipRouting: false,
          onClose: _ctx.close
        }, null, 8, ["item", "params", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}