import { useColorFilter } from 'app/functions/use-color-filter';
import { useReleaseFilter } from 'app/functions/use-release-filter';
import { useTextFilter } from 'app/functions/use-text-filter';
import { Annotation, AnnotationCategory } from 'app/models/annotation';
import { TitleRecord } from 'app/models/title';
import { Ref, computed, ref, watch } from 'vue';

export type AnnotationFilters = {
  [category in AnnotationCategory]: string[] | undefined;
};

export function useAnnotationFilters(annotations: Readonly<Ref<Annotation[]>>, releases: Readonly<Ref<TitleRecord[]>>) {
  const {
    releaseFilter,
    filteredItems: releaseFilteredAnnotations,
    getFilterObjects: releaseGetFilterObjects
  } = useReleaseFilter(annotations, releases);

  const {
    colorFilter,
    filteredItems: categoryFilteredAnnotations,
    getFilterObjects: colorGetFilterObjects
  } = useColorFilter(releaseFilteredAnnotations);

  const {
    textFilter,
    filteredItems: fullyFilteredAnnotations
  } = useTextFilter(categoryFilteredAnnotations, (a, text) => Annotation.FILTER_FUNCTIONS.filterByText(a, text));

  const ANNOTATIONS_PER_PAGE = 250;
  const currentPage = ref<number>(1);
  watch(fullyFilteredAnnotations, () => {
    currentPage.value = 1;
  });
  const paginatedAnnotations = computed(() => {
    return fullyFilteredAnnotations.value.reduce((pages, annotation, index) => {
      const pageIndex = Math.floor(index / ANNOTATIONS_PER_PAGE);
      if (!pages[pageIndex]) {
        pages[pageIndex] = [];
      }
      pages[pageIndex].push(annotation);

      return pages;
    }, [] as Annotation[][]);
  });
  const totalPages = computed(() => paginatedAnnotations.value.length);
  const annotationsToDisplay = computed(() => paginatedAnnotations.value[currentPage.value - 1] || []);


  const filterObjects = computed(() => [
    ...releaseGetFilterObjects(categoryFilteredAnnotations.value as any),
    ...colorGetFilterObjects(categoryFilteredAnnotations.value)
  ]);

  const displayCounts = computed(() => {
    return {
      showing: fullyFilteredAnnotations.value.length,
      total: categoryFilteredAnnotations.value.length
    };
  });

  return {
    textFilter,
    releaseFilter,
    colorFilter,
    currentPage,
    totalPages,

    annotationsToDisplay,
    pageableAnnotations: fullyFilteredAnnotations,
    selectableAnnotations: categoryFilteredAnnotations,
    filterObjects,
    displayCounts
  };
};

export const getQueryParametersFromAnnotationFilters = (filters: AnnotationFilters | undefined) => {
  if (!filters) { return {}; }

  return {
    color: filters.Color?.join(',') || undefined,
    release: filters.Release?.join(',') || undefined
  };
};
