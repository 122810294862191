import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitlePriorReleaseList = _resolveComponent("TitlePriorReleaseList")!
  const _component_SidebarDialog = _resolveComponent("SidebarDialog")!

  return (_openBlock(), _createBlock(_component_SidebarDialog, {
    heading: _ctx.$t('title.priorReleases'),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    secondHeader: _withCtx(() => [
      _createElementVNode("header", {
        class: _normalizeClass(_ctx.$style.header)
      }, [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.$style.title)
        }, _toDisplayString(_ctx.title.title), 3),
        (_ctx.title.subtitle)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.$style.subtitle)
            }, _toDisplayString(_ctx.title.subtitle), 3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_TitlePriorReleaseList, { title: _ctx.title }, null, 8, ["title"])
    ]),
    _: 1
  }, 8, ["heading"]))
}