import { APP } from 'app/base/app';
import { usePatron } from 'app/functions/use-patron';
import { Annotation } from 'app/models/annotation';
import { ref, watchEffect } from 'vue';

export default function useSyncedAnnotations() {
  const annotations = ref<Annotation[] | null>(null);
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const update = async () => {
    const { annotations: patronAnnotations } = usePatron();

    watchEffect(() => {
      annotations.value = patronAnnotations.value as Annotation[];
    });

    try {
      loading.value = true;
      await APP.patron.syncCoordinator.remoteSync({ type: ['annotations'] });
      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  update();

  return {
    annotations,
    loading,
    error,
    update
  };
}
