
import BackToTopButton from 'app/components/BackToTopButton.vue';
import { useDialog } from 'app/functions/use-dialog';
import { useResizer } from 'app/functions/use-resizer';
import { defineComponent, ref, SetupContext } from 'vue';

export default defineComponent({
  components: {
    BackToTopButton
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    headingClass: {
      type: String,
      default: undefined
    },
    /* Controls whether the escape key and clicking the backdrop close the dialog.
     * In most cases, just let this default to 'true' for better usability.
     */
     dismissible: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: undefined
    },
    side: {
      type: String as () => 'left' | 'right',
      default: 'right'
    },
    resizable: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx: SetupContext) => {
    const topOfContent = ref<HTMLElement | null>(null);
    const showBackToTop = ref(false);
    const focusOnScroll = '[data-close_focus]';

    // Match to CSS variable
    const animationDurationMs = 300;
    const minWidth = 300;

    const {
      animationOpen,
      closeDialog,
      dialog,
      styles
    } = useDialog(ctx, { animationDurationMs });

    const closeIfAllowed = (e: Event) => {
      e.preventDefault();

      if (props.dismissible) {
        closeDialog();
      }
    };

    const { resize } = useResizer(dialog, props.side, { minWidth: minWidth });

    return {
      animationOpen,
      dialog,
      focusOnScroll,
      minWidth,
      showBackToTop,
      styles,
      topOfContent,
      closeDialog,
      closeIfAllowed,
      resize
    };
  }
});
