
import Card from 'app/components/cards/Card.vue';
import Cover from 'app/components/Cover.vue';
import { DropdownOption } from 'app/components/Dropdown.vue';
import FormSelect from 'app/components/FormSelect.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { startCopy } from 'app/functions/use-annotation-copying';
import { useI18n } from 'app/functions/use-i18n';
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'ManualCopyContent',
  components: {
    Card,
    Cover,
    FormSelect,
    FormSubmitButton
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    releasesWithAnnotations: {
      type: Array as PropType<FilterObject[]>,
      required: true
    }
  },
  emits: [
    'loading',
    'submit'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();
    const loading = ref(false);

    const titleCard = computed(() => {
      return {
        id: props.title.slug,
        title: props.title.title,
        subtitle: props.title.subtitle
      };
    });

    const newestRelease = computed(() => {
      const name = getReleaseDisplayNameTitle(props.title);

      return {
        name: name,
        count: props.releasesWithAnnotations.find((release) => release.name === name)?.count || 0
      };
    });

    const releaseOptions = computed(() => {
      const opts = props.releasesWithAnnotations
      .filter((release) => release.name !== getReleaseDisplayNameTitle(props.title))
      .map((release) => {
        return {
          id: release.id,
          displayName: release.name,
          count: release.count,
          ariaLabel: t('annotationCopying.manualCopy.source.option', { RELEASE: release.name, N: release.count })
        } as DropdownOption;
      });

      return opts;
    });
    releaseOptions.value;

    const selectedRelease = ref<DropdownOption | null>(null);

    const apply = async () => {
      if (!selectedRelease.value) { return; }

      ctx.emit('loading');
      loading.value = true;
      // kick off new copy job
      await startCopy(props.title.slug, selectedRelease.value.id);

      // If the user starts a copy job, but hasn't opened the newest release yet,
      // switch them to the newest so they don't get new release / copying prompts
      const shouldUpdate = await props.title.isOutOfDate();

      if (shouldUpdate) {
        props.title.updateDownloads();
      }
      ctx.emit('submit');
    };

    return {
      apply,
      loading,
      newestRelease,
      releaseOptions,
      selectedRelease,
      titleCard
    };
  }
});
