
import { APP } from 'app/base/app';
import AlertDetails from 'app/components/AlertDetails.vue';
import AlertList from 'app/components/AlertList.vue';
import DynamicExpander from 'app/components/DynamicExpander.vue';
import Overlay from 'app/components/Overlay.vue';
import { AlertWithMetadata, useAlertsWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { useNetworkStatus } from 'app/functions/use-network-status';
import { GAContentInfo } from 'app/keys/analytics-keys';
import { AlertsWithMetadataSymbol } from 'app/keys/injection-keys';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, inject, nextTick, ref } from 'vue';

type State = {
  status: 'ready';
  alerts: AlertWithMetadata[];
} | {
  status: 'loading';
} | {
  status: 'error';
} | {
  status: 'details';
  alert: AlertWithMetadata;
} | {
  status: 'offline';
};

export default defineComponent({
  components: {
    AlertDetails,
    AlertList,
    DynamicExpander,
    Overlay
  },
  emits: [
    'close'
  ],
  setup: () => {
    const { reachable: network } = useNetworkStatus();
    const mySubLink = { name: RouteName.MySubscriptions };
    const transitionDuration = 300;

    const alertButton = ref<HTMLElement | null>(null);
    const backButton = ref<HTMLElement | null>(null);

    const { t } = useI18n();
    const headerLabel = ref(t('alert.header'));

    const showMenu = ref<boolean>(false);
    const alertDetailsAlert = ref<AlertWithMetadata>();

    const {
      alertsWithMetadata,
      error: alertsError,
      fetchAllAlerts,
      reviewAlert,
      deleteAlert,
      deleteAllAlerts
    } = inject(AlertsWithMetadataSymbol, useAlertsWithMetadata());

    const hasUnreviewedAlerts = computed(() => {
      if (!alertsWithMetadata.value) { return false; }

      return alertsWithMetadata.value.some((a) => !a.reviewed);
    });

    const state = computed<State>(() => {
      if (!network.value) {
        return {
          status: 'offline'
        };
      }

      if (alertsError.value) {
        return {
          status: 'error'
        };
      }

      if (alertDetailsAlert.value) {
        return {
          status: 'details',
          alert: alertDetailsAlert.value
        };
      }

      if (alertsWithMetadata.value) {
        return {
          status: 'ready',
          alerts: alertsWithMetadata.value || []
        };
      };

      return {
        status: 'loading'
      };
    });

    const openMenu = () => {
      showMenu.value = true;
      APP.tracking.log('view_alerts');
      fetchAllAlerts();
    };

    const closeMenu = () => {
      showMenu.value = false;
      alertDetailsAlert.value = undefined;
      alertButton.value?.focus();
    };

    const shouldShowActions = computed(() => ['ready', 'error'].includes(state.value.status));
    const allowClearAll = computed(() =>
      (state.value.status === 'ready' && state.value.alerts.length > 0) ||
      state.value.status === 'error'
    );

    const showAlertDetails = async (alert: AlertWithMetadata) => {
      alertDetailsAlert.value = alert;
      if (!alert.reviewed) {
        reviewAlert(alert.id);

        let contentInfo: GAContentInfo;
        if (alert.type === 'TitleNewRelease' || alert.type === 'TitleNotAvailable') {
          const item = alert.title;

          contentInfo = {
            is_set: false,
            title_slug: item.slug,
            title_name: item.subtitle ? `${item.title} ${item.subtitle}` : item.title
          };
        } else {
          const item = alert.series;

          contentInfo = {
            is_set: true,
            series_slug: item.id.toString(),
            series_name: item.name
          };
        }

        APP.tracking.log('review_alert', { alert_type: alert.type, ...contentInfo });
      }

      await nextTick();
      backButton.value?.focus();
      headerLabel.value = t('alert.reviewDetails', { type: t(`alert.type.${alert.type}`) });
    };

    const hideAlertDetails = () => {
      alertDetailsAlert.value = undefined;

      headerLabel.value = t('alert.header');
    };

    useAppEvents({
      'router:navigate': () => {
        if (showMenu.value) {
          closeMenu();
        }
      }
    });

    return {
      // Elements
      alertButton,
      backButton,

      // Properties
      alertsWithMetadata,
      alertDetailsAlert,
      allowClearAll,
      hasUnreviewedAlerts,
      headerLabel,
      mySubLink,
      shouldShowActions,
      showMenu,
      state,
      transitionDuration,

      // Functions
      deleteAlert,
      deleteAllAlerts,
      openMenu,
      closeMenu,
      showAlertDetails,
      hideAlertDetails
    };
  }
});
