
import AnnotationSelectionToolbar from 'app/components/AnnotationSelectionToolbar.vue';
import AnnotationTable from 'app/components/AnnotationTable.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import { watchMessage } from 'app/functions/use-chatterbox';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { useI18n } from 'app/functions/use-i18n';
import { useLibrary } from 'app/functions/use-library';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import AnnotationCardList from './AnnotationCardList.vue';

export type AnnotationDetailsAnnotation =
  SelectableAnnotation &
  {
    shown: boolean;
  };

export default defineComponent({
  components: {
    AnnotationTable,
    AnnotationSelectionToolbar,
    AnnotationCardList,
    FormCheckbox
},
  props: {
    annotations: {
      type: Array as PropType<AnnotationDetailsAnnotation[]>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    mobile: {
      type: Boolean,
      required: true
    },
    displayCounts: {
      type: Object as PropType<{ showing: number; total: number }>,
      required: true
    },
    selectAllState: {
      type: String as PropType<FormCheckboxState>,
      required: true
    },
    focusTargetId: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'annotations:update',
    'annotations:update:all'
  ],
  setup: (props, ctx) => {
    const toShow = computed(() => props.annotations.filter((a) => a.shown));
    const selected = computed(() => props.annotations.filter((a) => a.selected));

    const { t } = useI18n();
    const showingCount = computed(() => t(
      'annotations.showingCount',
      {
        SHOWING: props.displayCounts.showing,
        TOTAL: props.displayCounts.total
      }
    ));
    watchMessage('annotationsShowingCount', showingCount);

    const selectMode = ref(false);
    watchMessage('selectionMode', computed(() => selectMode.value ? t('annotations.select.on') : t('annotations.select.off')));
    watch(selectMode, () => {
      if (!selectMode.value) {
        deselectAll();
      }
    });

    const updateAnnotation = (annotations: AnnotationDetailsAnnotation[], update: AnnotationDetailsAnnotation) => {
      const index = annotations.findIndex((a) => a.uuid === update.uuid);
      annotations[index] = update;
    };

    const updateAnnotations = (updates: AnnotationDetailsAnnotation[]) => {
      const copy = props.annotations.slice();
      updates.forEach((u) => updateAnnotation(copy, u));
      ctx.emit('annotations:update', copy);
    };

    const selectAll = () => {
      ctx.emit('annotations:update:all', true);
    };

    const deselectAll = () => {
      ctx.emit('annotations:update:all', false);
    };

    const library = useLibrary();
    const hideNote = computed(() => library.value?.disableNotes);

    return {
      deselectAll,
      hideNote,
      selectAll,
      selected,
      selectMode,
      showingCount,
      toShow,
      updateAnnotations
    };
  }
});
