import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!
  const _component_PromptDialog = _resolveComponent("PromptDialog")!

  return (_openBlock(), _createBlock(_component_PromptDialog, {
    ref: "prompt",
    heading: _ctx.$t('circ.newRelease.header'),
    dismissible: false,
    onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prompt?.closeModal())),
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        id: `newrelease-explanation-${_ctx.titleId}`
      }, _toDisplayString(_ctx.$t('circ.newRelease.description')), 9, _hoisted_1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('circ.newRelease.downloadMessage')), 1),
      _createVNode(_component_FormSubmitButton, {
        autofocus: "",
        label: _ctx.$t('general.continue'),
        enabled: true,
        size: "small"
      }, null, 8, ["label"])
    ], undefined, true),
    _: 1
  }, 8, ["heading"]))
}