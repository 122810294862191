import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TitleDetailsContextMenu = _resolveComponent("TitleDetailsContextMenu")!
  const _component_CoverView = _resolveComponent("CoverView")!
  const _component_ManualCopySidebar = _resolveComponent("ManualCopySidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      id: "context-menu",
      ref: "contextMenuButton",
      class: [_ctx.$style.contextMenuButton, 'dark'],
      type: "button",
      "aria-haspopup": "dialog",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showContextMenu = true))
    }), [
      _createTextVNode(_toDisplayString(_ctx.$t('title.contextMenu.header')) + " ", 1),
      _createVNode(_component_Icon, { name: "overflow" })
    ], 16),
    (_ctx.showContextMenu)
      ? (_openBlock(), _createBlock(_component_TitleDetailsContextMenu, {
          key: 0,
          reference: _ctx.contextMenuButton,
          subscribeText: _ctx.subscribeTextLong,
          title: _ctx.title,
          allowCopying: _ctx.allowManualCopy,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContextMenu = false)),
          "onShow:cover": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCoverView = true)),
          "onShow:manualcopy": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showManualCopy = true)),
          "onToggle:subscription": _ctx.toggleSubscription
        }, null, 8, ["reference", "subscribeText", "title", "allowCopying", "onToggle:subscription"]))
      : _createCommentVNode("", true),
    (_ctx.showCoverView)
      ? (_openBlock(), _createBlock(_component_CoverView, {
          key: 1,
          title: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["title", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showManualCopy)
      ? (_openBlock(), _createBlock(_component_ManualCopySidebar, {
          key: 2,
          title: _ctx.title,
          releasesWithAnnotations: _ctx.releasesWithAnnotations,
          onClose: _ctx.hideOverlay
        }, null, 8, ["title", "releasesWithAnnotations", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}