import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "visually-hidden" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["id", "aria-label"]
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnnotationSelectionToolbar = _resolveComponent("AnnotationSelectionToolbar")!
  const _component_AnnotationTable = _resolveComponent("AnnotationTable")!
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_AnnotationCardList = _resolveComponent("AnnotationCardList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('annotations.label')), 1),
    (!_ctx.mobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.toolbar)
          }, [
            _createVNode(_component_AnnotationSelectionToolbar, {
              selected: _ctx.selected,
              focusTargetId: _ctx.focusTargetId,
              "onSelected:clear": _ctx.deselectAll
            }, null, 8, ["selected", "focusTargetId", "onSelected:clear"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.showingCount), 1)
          ], 2),
          (_ctx.toShow.length)
            ? (_openBlock(), _createBlock(_component_AnnotationTable, {
                key: 0,
                annotations: _ctx.toShow,
                title: _ctx.title,
                hideNote: _ctx.hideNote,
                selectAllState: _ctx.selectAllState,
                "onAnnotations:update": _ctx.updateAnnotations,
                "onAnnotations:update:all": _cache[0] || (_cache[0] = (select) => select ? _ctx.selectAll() : _ctx.deselectAll())
              }, null, 8, ["annotations", "title", "hideNote", "selectAllState", "onAnnotations:update"]))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('annotations.display.empty')), 1))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.toolbar)
          }, [
            _createElementVNode("button", {
              id: _ctx.focusTargetId,
              class: _normalizeClass([_ctx.$style.selectButton, _ctx.selectMode ? _ctx.$style.selectButtonActive : '']),
              "aria-label": _ctx.$t('annotations.select.toggle'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectMode = !_ctx.selectMode))
            }, _toDisplayString(_ctx.$t('annotations.select.label')), 11, _hoisted_3),
            _createElementVNode("p", null, _toDisplayString(_ctx.showingCount), 1)
          ], 2),
          (_ctx.selectMode)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.selectModeActions)
              }, [
                _createVNode(_component_FormCheckbox, {
                  modelValue: _ctx.selectAllState,
                  showLabel: false,
                  label: _ctx.$t('annotations.display.selectAll'),
                  ariaLabel: _ctx.$t('annotations.display.selectAll'),
                  class: _normalizeClass(_ctx.$style.checkbox),
                  disabled: _ctx.toShow.length === 0,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = (checked) => checked === 'on' ? _ctx.selectAll() : _ctx.deselectAll())
                }, null, 8, ["modelValue", "label", "ariaLabel", "class", "disabled"]),
                _createVNode(_component_AnnotationSelectionToolbar, {
                  selected: _ctx.selected,
                  "onSelected:clear": _ctx.deselectAll
                }, null, 8, ["selected", "onSelected:clear"])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.toShow.length)
            ? (_openBlock(), _createBlock(_component_AnnotationCardList, {
                key: 1,
                annotations: _ctx.toShow,
                selectable: _ctx.selectMode,
                title: _ctx.title,
                hideNote: _ctx.hideNote,
                "onAnnotations:update": _ctx.updateAnnotations
              }, null, 8, ["annotations", "selectable", "title", "hideNote", "onAnnotations:update"]))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('annotations.display.empty')), 1))
        ], 64))
  ], 64))
}