
import { defineComponent, ref } from 'vue';
import PromptDialog from './dialogs/PromptDialog.vue';
import FormInput from './FormInput.vue';
import FormSubmitButton from './FormSubmitButton.vue';

export default defineComponent({
  name: 'HoldPrompt',
  components: {
    FormInput,
    FormSubmitButton,
    PromptDialog
  },
  props: {
    titleId: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      default: ''
    }
  },
  emits: [
    'close',
    'hold'
  ],
  setup: (props, ctx) => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);

    const submitAndClose = () => {
      ctx.emit('hold', { email: email.value });
      prompt.value?.closeModal();
    };

    const email = ref(props.emailAddress);

    return {
      email,
      prompt,
      submitAndClose
    };
  }
});
