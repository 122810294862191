
import { SearchThisTitleQuery } from 'app/base/hudson';
import SearchThisTitleSidebar from 'app/components/SearchThisTitleSidebar.vue';
import AnnotationDetailsContextMenu from 'app/components/contextMenus/AnnotationDetailsContextMenu.vue';
import ExportQueueContextMenu from 'app/components/contextMenus/ExportQueueContextMenu.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { PropType, defineComponent, nextTick, ref } from 'vue';

type AnnotationContextMenuAttrs = {
} & {
  type: 'export-queue';
  exportOptions: boolean;
  showCount: boolean;
} | {
  type: 'annotation-details';
  showCount: boolean;
  showSearchOption: boolean;
};

export default defineComponent({
  components: {
    AnnotationDetailsContextMenu,
    ExportQueueContextMenu,
    SearchThisTitleSidebar
},
  props: {
    annotations: {
      type: Object as PropType<SelectableAnnotation[]>,
      required: true
    },
    attrs: {
      type: Object as PropType<AnnotationContextMenuAttrs>,
      required: true
    },
    buttonClass: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'selected:clear'
  ],
  setup: (props) => {
    const showContextMenu = ref(false);
    const showStT = ref(false);
    const actions = ref<HTMLElement | null>(null);
    const searchParams = ref<SearchThisTitleQuery | null>(null);

    const onSearch = () => {
      // StT option only available for single annotation context menus,
      // so annotations[0] is the only possible value
      searchParams.value = {
        query: props.annotations[0].quote,
        type: 'keyword',
        scope: 'title'
      } as SearchThisTitleQuery;

      showStT.value = true;
    };

    const closeSearch = async () => {
      searchParams.value = null;
      showStT.value = false;

      await nextTick();
      actions.value?.focus();
    };

    return {
      actions,
      searchParams,
      showContextMenu,
      showStT,
      closeSearch,
      onSearch
    };
  }
});
