
import { useDialog } from 'app/functions/use-dialog';
import { defineComponent, nextTick, onMounted, ref, SetupContext, watch } from 'vue';

export default defineComponent({
  props: {
    heading: {
      type: String,
      required: true
    },
    showBack: {
      type: Boolean,
      required: true
    },
    headingIcon: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'close',
    'back'
  ],
  setup: (props, ctx: SetupContext) => {
    const animationDurationMs = 300;

    const {
      animationOpen,
      closeDialog,
      dialog,
      styles
    } = useDialog(ctx, { animationDurationMs });

    const container = ref<HTMLElement | null>(null);
    const backButton = ref<HTMLButtonElement | null>(null);
    const dismissButton = ref<HTMLButtonElement | null>(null);

    const scrollTop = () => {
      container.value?.scrollIntoView({ behavior: 'smooth' });
    };

    // Refocus when transitioning content
    watch(() => props.showBack, async (shown) => {
      await nextTick();

      if (shown) {
        backButton.value?.focus();
      } else {
        dismissButton.value?.focus();
      }
    });

    onMounted(scrollTop);

    return {
      animationOpen,
      backButton,
      closeDialog,
      container,
      dialog,
      dismissButton,
      scrollTop,
      styles
    };
  }
});
