
import { defineComponent, ref } from 'vue';
import FormSubmitButton from './FormSubmitButton.vue';
import PromptDialog from './dialogs/PromptDialog.vue';

export default defineComponent({
  name: 'NtcPrompt',
  components: {
    FormSubmitButton,
    PromptDialog
  },
  props: {
    providerId: {
      type: String,
      default: 'provider'
    }
  },
  emits: [
    'submit',
    'close'
  ],
  setup: (_, ctx) => {
    const prompt = ref<InstanceType<typeof PromptDialog> | null>(null);
    const submitAndClose = () => {
      ctx.emit('submit');
      prompt.value?.closeModal();
    };


    return {
      prompt,
      submitAndClose
    };
  }
});
