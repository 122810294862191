
import Cover from 'app/components/Cover.vue';
import ManageCopyingContent from 'app/components/ManageCopyingContent.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ManageCopyingSidebar',
  components: {
    ManageCopyingContent,
    SidebarDialog,
    Cover
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    copyJobsState: {
      type: Object as PropType<CopyJobsState>,
      default: undefined
    }
  },
  emits: [
    'close'
  ]
});
