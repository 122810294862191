import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_HighlightColor = _resolveComponent("HighlightColor")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!
  const _component_AnnotationContextMenuButton = _resolveComponent("AnnotationContextMenuButton")!
  const _component_DynamicExpander = _resolveComponent("DynamicExpander")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.container]: true,
      [_ctx.$style.selectable]: _ctx.selectable
    })
  }, [
    (_ctx.selectable)
      ? (_openBlock(), _createBlock(_component_FormCheckbox, {
          key: 0,
          modelValue: _ctx.displayAnnotation.selected ? 'on' : 'off',
          showLabel: false,
          label: _ctx.$t('annotations.display.select'),
          ariaLabel: _ctx.$t('annotations.display.select'),
          class: _normalizeClass(_ctx.$style.checkbox),
          "onUpdate:modelValue": _ctx.updateSelected
        }, null, 8, ["modelValue", "label", "ariaLabel", "class", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_DynamicExpander, {
      panelId: `annotation-card-panel-${_ctx.displayAnnotation.id}`,
      headerId: _ctx.displayAnnotation.id,
      numElements: _ctx.expanded ? 2 : 1,
      class: _normalizeClass(_ctx.$style.panel)
    }, {
      default: _withCtx(() => [
        _createElementVNode("article", {
          class: _normalizeClass({
          [_ctx.$style.card]: true,
          [_ctx.$style.expanded]: _ctx.expanded,
          [_ctx.$style.selected]: _ctx.selectable && _ctx.displayAnnotation.selected
        })
        }, [
          (_ctx.selectable)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                ref: "selectButton",
                class: _normalizeClass(_ctx.$style.fullCardButton),
                "aria-label": _ctx.$t('annotations.display.select'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateSelected(_ctx.displayAnnotation.selected ? 'off' : 'on')))
              }, null, 10, _hoisted_1))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                ref: "expansionButton",
                class: _normalizeClass(_ctx.$style.fullCardButton),
                "aria-label": _ctx.$t('annotations.display.expandText'),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleExpansion && _ctx.toggleExpansion(...args)))
              }, null, 10, _hoisted_2)),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.colorStrip),
            style: _normalizeStyle(`--highlight-color: var(--highlight-color-${_ctx.displayAnnotation.colorGroup})`),
            "aria-hidden": "true"
          }, null, 6),
          _createElementVNode("p", {
            class: _normalizeClass({
            [_ctx.$style.text]: true,
            [_ctx.$style.truncatedLong]: !_ctx.expanded
          })
          }, _toDisplayString(_ctx.displayAnnotation.highlight), 3),
          _createElementVNode("dl", {
            class: _normalizeClass(_ctx.$style.metadataList)
          }, [
            (!_ctx.hideNote)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotations.display.property.note')), 1),
                  (_ctx.displayAnnotation.note)
                    ? (_openBlock(), _createElementBlock("dd", {
                        key: 0,
                        class: _normalizeClass({
                [_ctx.$style.text]: true,
                [_ctx.$style.truncated]: !_ctx.expanded
              })
                      }, _toDisplayString(_ctx.displayAnnotation.note), 3))
                    : (_openBlock(), _createElementBlock("dd", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style.noNote)
                      }, _toDisplayString(_ctx.$t('annotations.display.noNote')), 3))
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotations.display.property.citation')), 1),
            (!_ctx.selectable && _ctx.displayAnnotation.releaseTitleRecord)
              ? (_openBlock(), _createElementBlock("dd", _hoisted_3, [
                  _createVNode(_component_TitleActionButton, {
                    title: _ctx.displayAnnotation.releaseTitleRecord,
                    parent: _ctx.displayAnnotation.parentTitleRecord,
                    seekTo: {
                type: 'highlight',
                location: _ctx.displayAnnotation.id
              },
                    label: _ctx.displayAnnotation.citation,
                    truncate: true,
                    class: _normalizeClass({
                [_ctx.$style.titleActionButton]: true,
                [_ctx.$style.text]: true,
                [_ctx.$style.linkText]: true,
                [_ctx.$style.truncated]: !_ctx.expanded
              })
                  }, null, 8, ["title", "parent", "seekTo", "label", "class"])
                ]))
              : (_openBlock(), _createElementBlock("dd", {
                  key: 2,
                  class: _normalizeClass({ [_ctx.$style.truncated]: !_ctx.expanded })
                }, _toDisplayString(_ctx.displayAnnotation.citation), 3)),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annotations.display.property.release')), 1),
            _createElementVNode("dd", {
              class: _normalizeClass({ [_ctx.$style.truncated]: !_ctx.expanded })
            }, _toDisplayString(_ctx.displayAnnotation.releaseDisplayName), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.footer)
          }, [
            _createElementVNode("dl", {
              class: _normalizeClass(_ctx.$style.footerMetadataList)
            }, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t('annotations.display.property.color')), 1),
              _createElementVNode("dd", null, [
                _createVNode(_component_HighlightColor, {
                  colorGroup: _ctx.displayAnnotation.colorGroup
                }, null, 8, ["colorGroup"])
              ]),
              _createElementVNode("dt", _hoisted_5, _toDisplayString(_ctx.$t('annotations.display.property.lastUpdated')), 1),
              _createElementVNode("dd", null, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.lastUpdated)
                }, [
                  _createVNode(_component_Icon, {
                    name: "expire-clock",
                    "aria-hidden": "true"
                  }),
                  _createVNode(_component_RelativeDate, {
                    timestamp: _ctx.displayAnnotation.lastUpdated
                  }, null, 8, ["timestamp"])
                ], 2)
              ])
            ], 2),
            (!_ctx.selectable)
              ? (_openBlock(), _createBlock(_component_AnnotationContextMenuButton, {
                  key: 0,
                  annotations: [_ctx.annotation],
                  buttonClass: _ctx.$style.actions,
                  attrs: _ctx.contextMenuType === 'export-queue' ? {
              type: _ctx.contextMenuType,
              showCount: false,
              exportOptions: false
            } : {
              type: _ctx.contextMenuType,
              showCount: false,
              showSearchOption: true
            }
                }, null, 8, ["annotations", "buttonClass", "attrs"]))
              : _createCommentVNode("", true)
          ], 2)
        ], 2)
      ], undefined, true),
      _: 1
    }, 8, ["panelId", "headerId", "numElements", "class"])
  ], 2))
}