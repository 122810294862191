import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_UserMenuMain = _resolveComponent("UserMenuMain")!
  const _component_UserMenuAbout = _resolveComponent("UserMenuAbout")!
  const _component_UserMenuDownload = _resolveComponent("UserMenuDownload")!
  const _component_SiteMenu = _resolveComponent("SiteMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      "aria-label": _ctx.$t('header.account.label'),
      class: _normalizeClass(_ctx.$style.accountDropdown),
      "data-menu_focus": "",
      "aria-haspopup": "dialog",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)))
    }, [
      _createVNode(_component_Icon, { name: "user" })
    ], 10, _hoisted_1),
    (_ctx.showMenu)
      ? (_openBlock(), _createBlock(_component_SiteMenu, {
          key: 0,
          heading: _ctx.$t('header.account.label'),
          headingIcon: "user",
          showBack: _ctx.currentMenu !== 'main',
          onBack: _cache[1] || (_cache[1] = () => _ctx.changeMenu('main')),
          onClose: _ctx.closeMenu
        }, {
          default: _withCtx(() => [
            (_ctx.currentMenu === 'main')
              ? (_openBlock(), _createBlock(_component_UserMenuMain, {
                  key: 0,
                  onClose: _ctx.closeMenu,
                  onMenu: _ctx.changeMenu
                }, null, 8, ["onClose", "onMenu"]))
              : (_ctx.currentMenu === 'about')
                ? (_openBlock(), _createBlock(_component_UserMenuAbout, { key: 1 }))
                : (_ctx.currentMenu === 'download')
                  ? (_openBlock(), _createBlock(_component_UserMenuDownload, { key: 2 }))
                  : _createCommentVNode("", true)
          ], undefined, true),
          _: 1
        }, 8, ["heading", "showBack", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}