
import { Dictionary } from 'app/base/common';
import SeriesTableOfContents from 'app/components/SeriesTableOfContents.vue';
import TableOfContents from 'app/components/TableOfContents.vue';
import SidebarDialog from 'app/components/dialogs/SidebarDialog.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'TableOfContentsSidebar',
  components: {
    TableOfContents,
    SeriesTableOfContents,
    SidebarDialog
},
  props: {
    item: {
      type: Object as () => Series | TitleRecord,
      required: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props) => {
    const { windowHeight } = useWindowSize();
    const hideMetadata = computed(() => {
      return windowHeight.value <= Breakpoint.VeryNarrow;
    });

    const itemType = computed(() => {
      return props.item instanceof Series
        ? 'series'
        : 'title';
    });

    const volumes = ref<{ id: number; all: Dictionary<TitleRecord> } | undefined>(undefined);

    watch(() => props.show, async (to) => {
      if (!to) { return; }

      if (props.item instanceof Series) {
        if (volumes.value?.id === props.item.id) {
          return;
        }

        const volumeDictionary = await props.item.getAllTitleObjects();
        volumes.value = {
          id: props.item.id,
          all: volumeDictionary
        };
      } else {
        volumes.value = undefined;
      }
    }, { immediate: true });


    const metadataList = computed(() => {
      if (props.item instanceof Series) { return []; }

      const meta = props.item.lexisMetadata;

      const list = (['edition', 'release', 'releaseDate'] as const)
        .filter((m) => !!meta[m])
        .map((m) => {
          return {
            label: `title.${m}`,
            value: meta[m]!,
            subs: {}
          };
        });

      if (props.item.creators?.Author?.length) {
        list.push({
          label: 'title.creator',
          value: props.item.creators.Author[0].name,
          subs: { n: 1 }
        });
      }

      return list;
    });

    return {
      hideMetadata,
      itemType,
      metadataList,
      volumes
    };
  }
});
