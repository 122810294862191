import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewReleasePrompt = _resolveComponent("NewReleasePrompt")!
  const _component_CopyingPrompt = _resolveComponent("CopyingPrompt")!
  const _component_InProgressPrompt = _resolveComponent("InProgressPrompt")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.promptToShow === 'standard')
      ? (_openBlock(), _createBlock(_component_NewReleasePrompt, {
          key: 0,
          titleId: _ctx.titleId,
          onOpen: _ctx.openBook
        }, null, 8, ["titleId", "onOpen"]))
      : _createCommentVNode("", true),
    (_ctx.promptToShow === 'copy')
      ? (_openBlock(), _createBlock(_component_CopyingPrompt, {
          key: 1,
          targetTitle: (_ctx.title as TitleRecord),
          sourceTitle: _ctx.mostRecentRelease ? _ctx.mostRecentRelease.release : undefined,
          sourceAnnotations: _ctx.mostRecentRelease ? _ctx.mostRecentRelease.annotations : undefined,
          onOpen: _ctx.openBook,
          onCopy: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copying = true))
        }, null, 8, ["targetTitle", "sourceTitle", "sourceAnnotations", "onOpen"]))
      : _createCommentVNode("", true),
    (_ctx.promptToShow === 'inProgress')
      ? (_openBlock(), _createBlock(_component_InProgressPrompt, {
          key: 2,
          targetTitle: (_ctx.title as TitleRecord),
          onOpen: _ctx.openBook,
          onClose: _ctx.close
        }, null, 8, ["targetTitle", "onOpen", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}