import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationSidebar = _resolveComponent("NavigationSidebar")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DebugConsole = _resolveComponent("DebugConsole")!
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_GdprModal = _resolveComponent("GdprModal")!
  const _component_NewRelease = _resolveComponent("NewRelease")!
  const _component_NtcPrompt = _resolveComponent("NtcPrompt")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.arena)
  }, [
    _createElementVNode("div", {
      ref: "surfaces",
      class: _normalizeClass([_ctx.$style.surfaces, _ctx.showNav ? _ctx.$style.showNav : ''])
    }, [
      (_ctx.showChrome)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.mobile)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.showNav)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style.shield),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNav = false))
                      }, null, 2))
                    : _createCommentVNode("", true),
                  _createVNode(_Transition, { name: "nav" }, {
                    default: _withCtx(() => [
                      (_ctx.showNav)
                        ? (_openBlock(), _createBlock(_component_FocusTrap, {
                            key: 0,
                            onDeactivate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNav = false))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_NavigationSidebar, {
                                class: _normalizeClass(_ctx.$style.nav),
                                style: {"z-index":"unset"}
                              }, null, 8, ["class"])
                            ], undefined, true),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], undefined, true),
                    _: 1
                  })
                ], 64))
              : (_openBlock(), _createBlock(_component_NavigationSidebar, {
                  key: 1,
                  class: _normalizeClass(_ctx.$style.nav),
                  showSurvey: _ctx.showSurvey,
                  onCloseSurvey: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSurvey = false))
                }, null, 8, ["class", "showSurvey"])),
            _createVNode(_component_HeaderBar, {
              class: _normalizeClass(_ctx.$style.header),
              onShowNav: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showNav = true))
            }, null, 8, ["class"])
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: _ctx.transitionName }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                class: _normalizeClass(_ctx.$style.surface)
              }, null, 8, ["class"]))
            ], undefined, true),
            _: 2
          }, 1032, ["name"]),
          _createVNode(_component_Toast)
        ]),
        _: 1
      })
    ], 2),
    _createVNode(_component_Overlay, {
      show: _ctx.showDebug,
      transition: _ctx.narrow ? 'down' : 'up',
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDebug = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DebugConsole)
      ], undefined, true),
      _: 1
    }, 8, ["show", "transition"]),
    _createVNode(_component_Loading, {
      class: _normalizeClass(_ctx.$style.arenaTitleLoading)
    }, null, 8, ["class"]),
    (_ctx.shouldShowSettings)
      ? (_openBlock(), _createBlock(_component_GdprModal, {
          key: 0,
          defaultSetting: _ctx.defaultSetting,
          onSubmit: _ctx.saveSettings,
          onClose: _ctx.hideSettings
        }, null, 8, ["defaultSetting", "onSubmit", "onClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_NewRelease),
    (_ctx.shouldShowNtc)
      ? (_openBlock(), _createBlock(_component_NtcPrompt, {
          key: 1,
          providerId: _ctx.providerId,
          onSubmit: _ctx.submitNtc,
          onClose: _ctx.hideNtc
        }, null, 8, ["providerId", "onSubmit", "onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}