
import SiteMenu from 'app/components/dialogs/SiteMenu.vue';
import UserMenuAbout from 'app/components/UserMenuAbout.vue';
import UserMenuDownload from 'app/components/UserMenuDownload.vue';
import UserMenuMain from 'app/components/UserMenuMain.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { defineComponent, nextTick, ref } from 'vue';

type Menu = 'main' | 'download' | 'about';

export default defineComponent({
  components: {
    SiteMenu,
    UserMenuMain,
    UserMenuDownload,
    UserMenuAbout
  },
  emits: [
    'close'
  ],
  setup: () => {
    const showMenu = ref<boolean>(false);

    const currentMenu = ref<Menu>('main');
    const changeMenu = (val: Menu) => currentMenu.value = val;

    const openMenu = () => {
      showMenu.value = true;
    };

    const closeMenu = () => {
      showMenu.value = false;
      currentMenu.value = 'main';
    };

    useAppEvents({
      'open:download:settings': async () => {
        showMenu.value = true;
        await nextTick();
        changeMenu('download');
      }
    });

    return {
      changeMenu,
      closeMenu,
      currentMenu,
      openMenu,
      showMenu
    };
  }
});
